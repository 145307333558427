import React from 'react'
import { useNavigate } from "react-router-dom";
import Avatar from "./../components/Avatar";
import Error from "./../components/Error";
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Tooltip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AnswerReferenceComponent from './AnswerReferenceComponent';
import PostAddIcon from '@mui/icons-material/PostAdd';

export default function AnswerChatComponent(props) {
    const navigate = useNavigate();
    const { chat, handleClickOpenDetailReference, idx, isConnecting, suggestQuestion } = props;

    function showQuestions(data) {
        const result = [];
        const questions = data.split("\n");

        for (let i = 0; i < questions.length; i++) {
            result.push(questions[i].replace(i + 1 + '.', ''));
        }

        return result;
    }

    if (chat.botMessage && chat.questtions) {
        return <div className="chatLog" key={idx}>
            <div className="botMessageMainContainer" style={{ width: '100%' }}>
                <div className="chatPromptWrapper" style={{ float: 'left' }}>
                    <div>
                        <div>
                            <h4 style={{ textAlign: 'left', width: '100%' }}>
                                Summary of the context:
                            </h4>
                            {
                                <span style={{ float: 'left', textAlign: 'left', marginBottom: '10px', color: 'teal' }}>
                                    {chat.botMessage}
                                </span>
                            }
                        </div>

                        <h4 style={{ textAlign: 'left', width: '100%' }}>Here are some questions that can be asked: </h4>

                        {showQuestions(chat.questtions).length >= 2 &&
                            <div>
                                {
                                    showQuestions(chat.questtions).map((question, index) => (
                                        <div key={index} style={{ marginBottom: '10px', color: 'teal', cursor: 'pointer' }}
                                            disabled={isConnecting} onClick={() => { suggestQuestion(question) }}>
                                            {question}
                                        </div>
                                    ))
                                }
                            </div>
                        }

                    </div>

                </div>
            </div>
        </div>
    }

    return (
        <div className="chatLog" key={idx}>
            <div className="chatPromptMainContainer" style={{ width: '100%' }}>
                <div className="chatPromptWrapper" style={{ float: 'right' }}>
                    <div id="chatPrompt" style={{ backgroundColor: 'rgb(255 247 238)', borderRadius: '15px', padding: '20px' }}>{chat.chatPrompt}</div>
                    <div className='icon-chat'>
                        <Avatar bg="#fe9200" className="userSVG">
                            <PersonOutlineOutlinedIcon style={{ color: 'white', fontSize: '3em' }} />
                        </Avatar>
                    </div>
                </div>
            </div>

            <div className="botMessageMainContainer" style={{ width: '100%' }}>
                <div className="chatPromptWrapper" style={{ float: 'left' }}>
                    <div className='icon-chat'>
                        <Avatar bg="#11a27f" className="userSVG">
                            <SmartToyOutlinedIcon style={{ color: 'white', fontSize: '3em' }} />
                        </Avatar>
                    </div>
                    {chat.botMessage ? (
                        <div id="botMessage">
                            <div style={{ backgroundColor: 'rgb(246 255 253)', borderRadius: '15px', padding: '20px', whiteSpace: 'pre-line', display: 'table' }}>
                                <div dangerouslySetInnerHTML={{ __html: chat.botMessage }}></div>
                                <AnswerReferenceComponent chat={chat} handleClickOpenDetailReference={handleClickOpenDetailReference}></AnswerReferenceComponent>
                            </div>
                        </div>
                    ) : <></>}

                    {/* ----outline for export file document------- */}
                    {!isConnecting && chat && chat.botMessage && <Tooltip title="Add to note">
                        <div className='icon-chat' style={{ justifyContent: 'flex-start' }}>
                            {/* <MoreVertIcon onClick={() => { props.handleSaveIntoSection(chat) }} style={{ fontSize: '2em', cursor: 'pointer' }} /> */}
                            <PostAddIcon onClick={() => { props.handleSaveIntoSection(chat) }} style={{ fontSize: '2em', cursor: 'pointer', color: 'rgb(2, 151, 161)' }} />
                        </div>
                    </Tooltip>}

                </div>
            </div>
        </div>
    )
}
