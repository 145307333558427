import React from "react";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

import { toast } from 'react-toastify';
import axios from './../configs'
import { useState } from 'react';

const NewChat = ({ setChatLog, setShowMenu, newChat }) => {

  return (
    <div style={{ width: '224px' }}>
      {/* <div style={{ fontSize: '2.5em', fontWeight: 'bold', marginBottom: '20px', fontFamily: 'Brush Script MT', textShadow: '1px 0px 4px #076d6e' }}>Conversations</div> */}
      {/* <div
        className="sideMenuButton"
        onClick={() => {
          setChatLog([]);
          setShowMenu(false);
        }}
        style={{ fontWeight: 'bold' }}
      >
        <span>+</span>
        New chat
      </div> */}
      <Button
        variant="outlined" color="success"
        onClick={async () => {
          newChat();
          setChatLog([]);
          setShowMenu(false);
        }}
        style={{ fontWeight: 'bold', width: '100%' }}
      >
        <AddIcon style={{ marginRight: '10px' }} />
        <span>New chat</span>

      </Button>
    </div>
  );
};

export default NewChat;
