import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Avatar, Checkbox, DialogActions, Divider, IconButton, InputAdornment, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, TextField } from '@mui/material';
import axios from '../configs';
import ArticleIcon from '@mui/icons-material/Article';
import { teal } from '@mui/material/colors';
import SearchIcon from '@mui/icons-material/Search';
import { toast } from 'react-toastify';
import UploadDocument from './UploadDocument';
import AddIcon from '@mui/icons-material/Add';
import LoadingData from '../components/LoadingData';
import ViewPdfDocument from '../components/ViewDocument';


export default function DocumentsOfTraining(props) {

    const { onClose, open, onSubmited } = props;
    const auth_token = localStorage.getItem("auth_token");
    const auth_token_type = localStorage.getItem("auth_token_type");
    const token = auth_token_type + " " + auth_token;

    const selectedTraining = props.selectedTraining;

    const [openViewPdf, setOpenViewPdf] = React.useState(false);

    const closeDialog = (e, reason) => {
        if (reason === 'escapeKeyDown') {
            return;
        }
        if (reason === 'backdropClick') {
            return;
        }
        onClose();
    };

    const [documents, setDocuments] = React.useState([]);
    const [searchText, setSearchText] = React.useState("");
    const [selectedDocument, setSelectedDocument] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        setDocuments([]);
        setSearchText('');
        onSearch('');
    }, []);

    const handleSearchChange = (event) => {
        const searchTextValue = event.target.value;
        setSearchText(searchTextValue);
        setTimeout(() => {
            // do search logic here           
            if (searchTextValue) {
                onSearch(searchTextValue);
            } else {
                onSearch("");
            }
        }, 800);
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            onSearch(searchText);
        }
    };

    const handleSearch = (event) => {
        onSearch(searchText);
    }

    const onSearch = async (search_text) => {
        try {
            // const res = await axios.get("/training/get-list-docs-of-training", {
            //     headers: { Authorization: token }, params: { key_search: search_text, training_id: selectedTraining.id }
            // })
            const res = await axios.get(`/training/get-list-docs-of-training?key_search=${search_text}&training_id=${selectedTraining.training_id}`, {
                headers: { Authorization: token }
            })
                .then(response => {
                    setIsLoading(false);
                    const resTemp = response.data.result;
                    setDocuments(resTemp);
                });

        } catch (e) {
            console.error(e);
        }
    };

    const handleDocumentClick = (selectedDoc) => {
        setSelectedDocument(selectedDoc);
        // closeDialog();
        setOpenViewPdf(true);
    };


    return (

        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={open}
            onClose={closeDialog}>
            <DialogTitle>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>{selectedTraining.name} - version {selectedTraining.version}</div>
                </div>
            </DialogTitle>
            <Divider />
            <div style={{ width: '100%', padding: '10px' }}>
                <TextField
                    label="Search"
                    variant="outlined"
                    value={searchText}
                    onChange={handleSearchChange}
                    onKeyPress={handleKeyPress}
                    style={{ width: '100%' }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleSearch}>
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>,
                    }}
                />

            </div>
            <DialogContent>
                <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {isLoading ? (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <LoadingData />
                        </div>
                    ) : (documents.length > 0 ? documents.map((doc) => {
                        const documentId = doc.id;
                        return (
                            <div key={doc.id}>
                                <ListItem>
                                    <ListItemButton onClick={() => handleDocumentClick(doc)}>
                                        <ListItemAvatar>
                                            <Avatar sx={{ bgcolor: teal[100], color: teal[600] }}>
                                                <ArticleIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText id={doc.id} primary={doc.filename} secondary={`Type: ${doc.type}`} />
                                    </ListItemButton>
                                </ListItem>
                                <Divider light />
                            </div>
                        );
                    }) : <h5>No data to display</h5>
                    )}
                </List>
                {/* ------ end list check box -------*/}
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>Cancel</Button>
                {/* <Button onClick={handleSaveChangeAdd}>Save Change</Button> */}
            </DialogActions>

            <div>
                {openViewPdf && <ViewPdfDocument open={openViewPdf} filename={selectedDocument.filename} id={selectedDocument.id} onClose={() => { setOpenViewPdf(false); }}></ViewPdfDocument>}
            </div>
        </Dialog>
    );

}