import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Avatar, Checkbox, DialogActions, Divider, IconButton, InputAdornment, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, TextField } from '@mui/material';
import axios from '../configs';
import ArticleIcon from '@mui/icons-material/Article';
import { teal } from '@mui/material/colors';
import SearchIcon from '@mui/icons-material/Search';
import LoadingData from '../components/LoadingData';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useAppStore } from "../utils/appstate";

// function for tabs--------------------
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                   {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
//--------------------------------------

export default function TrainingFileDocument(props) {
    const { onClose, open, onSubmited, selectedValue, selectedValueTraining } = props;
    const auth_token = localStorage.getItem("auth_token");
    const auth_token_type = localStorage.getItem("auth_token_type");
    const token = auth_token_type + " " + auth_token;
    const closeDialog = (e, reason) => {
        if (reason === 'escapeKeyDown') {
            return;
        }
        if (reason === 'backdropClick') {
            return;
        }
        onClose();
    };

    const [documents, setDocuments] = React.useState([]);
    const [searchText, setSearchText] = React.useState("");
    const [selectedDocument, setSelectedDocument] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);

    const [trainings, setTrainings] = React.useState([]);
    const [selectedTraining, setSelectedTraining] = React.useState(null);

    // -------tabs--------------------
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    //------------------------
    React.useEffect(() => {
        setDocuments([]);
        setSearchText('');
        onSearch('');

        setTrainings([]);
        onSearchTraining('');

    }, []);

    const handleSearchChange = (event) => {
        const searchTextValue = event.target.value;
        setSearchText(searchTextValue);
        setTimeout(() => {
            // do search logic here           
            if (searchTextValue) {
                onSearch(searchTextValue);
            } else {
                onSearch("");
            }
        }, 800);
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            onSearch(searchText);
        }
    };

    const handleSearch = (event) => {
        onSearch(searchText);
    }

    const onSearch = async (search_text) => {
        try {
            const res = await axios.get("/training/get-list-docs-for-chat-with-search-keyword", {
                headers: { Authorization: token }, params: { key_search: search_text }
            })
                .then(response => {
                    setIsLoading(false);
                    const resTemp = response.data.result;
                    setDocuments(resTemp);
                   
                   
                });
                

        } catch (e) {
            console.error(e);
        }
    };


    const handleDocumentClick = (selectedDoc) => {
        setSelectedDocument(selectedDoc);
        closeDialog();
        selectedValue(selectedDoc);
    };

    // ----for tab training------------------------------
    const handleSearchTrainingChange = (event) => {
        const searchTextValue = event.target.value;
        setSearchText(searchTextValue);
        setTimeout(() => {
            // do search logic here           
            if (searchTextValue) {
                onSearchTraining(searchTextValue);
            } else {
                onSearchTraining("");
            }
        }, 800);
    };

    const handleKeyPressTraining = (event) => {
        if (event.key === "Enter") {
            onSearchTraining(searchText);
        }
    };

    const handleSearchTraining = (event) => {
        onSearchTraining(searchText);
    }

    const onSearchTraining = async (search_text) => {
        try {
            const res = await axios.get("/training/get-list-approved-training-for-chat-with-search-keyword", {
                headers: { Authorization: token }, params: { key_search: search_text }
            })
                .then(response => {
                    setIsLoading(false);
                    const resTemp = response.data.result;
                    setTrainings(resTemp);
                });

        } catch (e) {
            console.error(e);
        }
    };
    function uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    // const appInfo = useAppStore((state) => state.AppInfo);
    // const updateAppInfo = useAppStore((state) => state.updateAppInfo);

    // const handleTrainingClick = (selectedTrain) => {
    //     setSelectedTraining(selectedTrain);
    //     debugger
    //     closeDialog();
    //     selectedValueTraining(selectedTrain)
    //     //return slected training value from dialog TrainingMultiFileDocuemnt

    //     appInfo.SelectedTraining = {
    //         id: uuidv4(),
    //         training_id: selectedTrain.training_id,
    //         name: selectedTrain.name,
    //         version: selectedTrain.version,
    //         vector_file_path: selectedTrain.vector_file_path
    //     }

    //     updateAppInfo({ ...appInfo });
    //     //------------

    //     onSubmited(selectedTrain);

    // };
    const handleTrainingClick = (selectedTrain) => {
        setSelectedTraining(selectedTrain);
        closeDialog();
        selectedValueTraining(selectedTrain);
    };
    //-----end for tab training---------------------------


    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={open}
            onClose={closeDialog}>
            <DialogTitle>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>Choose available context</div>
                </div>
            </DialogTitle>
            <Divider />

            <DialogContent>
                {/*------------tabs----------------*/}
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Training" {...a11yProps(0)} />
                            <Tab label="Document" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        {/*------- search training---------*/}
                        <div style={{ width: '100%', padding: '10px' }}>
                            <TextField
                                label="Search"
                                variant="outlined"
                                value={searchText}
                                onChange={handleSearchTrainingChange}
                                onKeyPress={handleKeyPressTraining}
                                style={{ width: '100%' }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleSearchTraining}>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                            />
                        </div>
                        {/* ------ content for tab training -------*/}
                        <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                            {isLoading ? (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <LoadingData />
                                </div>
                            ) : (trainings.length > 0 ? trainings.map((training) => {
                                return (
                                    <div key={training.training_id}>
                                        <ListItem>
                                            <ListItemButton onClick={() => handleTrainingClick(training)}>
                                                <ListItemAvatar>
                                                    <Avatar sx={{ bgcolor: teal[100], color: teal[600] }}>
                                                        <ArticleIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText id={training.training_id} primary={training.name} secondary={`Version: ${training.version}`} />
                                            </ListItemButton>
                                        </ListItem>
                                        <Divider light />
                                    </div>
                                );
                            }) : <h5>No data to display</h5>)
                            }
                        </List>
                        {/* ------ end tabs training -------*/}
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        {/*------- search document ---------*/}
                        <div style={{ width: '100%', padding: '10px' }}>
                            <TextField
                                label="Search"
                                variant="outlined"
                                value={searchText}
                                onChange={handleSearchChange}
                                onKeyPress={handleKeyPress}
                                style={{ width: '100%' }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleSearch}>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                            />
                        </div>
                        {/*-----end search---------------- */}
                        {/* ------ content for tab document -------*/}
                        <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                            {isLoading ? (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <LoadingData />
                                </div>
                            ) : (documents.length > 0 ? documents.map((doc) => {
                                const documentId = doc.id;
                                return (
                                    <div key={doc.id}>
                                        <ListItem>
                                            <ListItemButton onClick={() => handleDocumentClick(doc)}>
                                                <ListItemAvatar>
                                                    <Avatar sx={{ bgcolor: teal[100], color: teal[600] }}>
                                                        <ArticleIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText id={doc.id} primary={doc.filename} secondary={`Type: ${doc.type}`} />
                                            </ListItemButton>
                                        </ListItem>
                                        <Divider light />
                                    </div>
                                );
                            }) : <h5>No data to display</h5>
                            )}
                        </List>
                        {/* ------ end tabs document -------*/}
                    </CustomTabPanel>
                </Box>
                {/*----------end tabs--------------*/}

            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>Cancel</Button>
                {/* <Button onClick={handleSaveChangeAdd}>Save Change</Button> */}
            </DialogActions>
        </Dialog>
    );
}