import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import '../App.css'
import './Training.css'
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { toast } from "react-toastify";
import axios from './../configs'
import { Link, useNavigate, } from "react-router-dom"
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Utils from './../utils/utils';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { IconButton, Tooltip } from '@mui/material';
import { Check, DoneAll } from '@mui/icons-material';

const TrainingData = () => {
  const history = useNavigate();

  const handleUseTraining = async (training_id) => {
    const data = {
      training_id: training_id,
      name: "",
      version: "",
      status: "",
      vector_file_path: "",
      json_file_path: "",
      created_user: "",
      modified_user: "",
      recommendation_questions: "",
      summary_content: ""
    }
    await axios
      .post("/training/use-training-data", data, { headers: { Authorization: token } })
      .then((response) => {
        fetchData();
        toast.success(response.data.detail);
      })
      .catch((error) => {
        toast.error(error.response.data.detail);
      });
  }
  // design ui using data grid-------------
  const columns = [
    // { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'name',
      headerName: 'Training Name',
      width: 250,
      editable: true,
    },
    {
      field: 'version',
      headerName: 'Version',
      width: 150,
      editable: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      // type: 'number',
      width: 150,
      editable: true,
    },
    {
      field: 'modified_at',
      headerName: 'Modified Date',
      // type: 'number',
      width: 200,
      editable: true,
      valueFormatter: (params) => {
        if (params.value === 'N/A') {
          return params.value;
        }
        return Utils.formatDateTime(params.value);
      },
    },
    {
      field: 'Actions',
      headerName: 'Actions', width: 250,
      renderCell: (params) => (
        <>
          <Tooltip title="View detail">
            <IconButton
              aria-label="view detail" size="large"
              onClick={(event) => {
                history('/training-data-detail?id=' + encodeURIComponent(params.row.id));
              }}
              style={{ padding: 10 }}
            >
              <VisibilityIcon style={{ color: 'teal' }} />

            </IconButton>
          </Tooltip>

          <Tooltip title="Delete">
            <IconButton
              aria-label="Delete" size="large"
              onClick={(event) => {
                setSelectedRow(params.row.id);
                setConfirmDialogOpen(true);
              }}
              style={{ padding: 10 }}
            >
              <DeleteOutlineOutlinedIcon style={{ color: 'red' }} />

            </IconButton>
          </Tooltip>
          {params.row.status === 'approved' ? params.row.is_used ? <Tooltip title="Using">
            <IconButton
              style={{ padding: 10, color: 'teal' }}
            >
              <DoneAll />
            </IconButton>
          </Tooltip> :
            <Tooltip title="Not use">
              <IconButton
                size="large"
                onClick={(event) => {
                  handleUseTraining(params.row.id);
                }}
                style={{ padding: 10 }}
              >
                <Check />
              </IconButton>
            </Tooltip>
            : <></>}
        </>
      ),
    },
  ];

  //-----------------end design data grid-------------

  // ---------confirm dialog------
  const [selectedRow, setSelectedRow] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const handleDelete = () => {
    removeTrainingData(selectedRow);
    setConfirmDialogOpen(false);
  };
  //-------------------------

  // remove training data------
  const removeTrainingData = async (training_id) => {
    // event.preventDefault();
    const data = {
      // training_id: training_id,
      // name: "",
      // version: "",
      // status: "",
      // vector_file_path: "",
      // created_user: "",
      // modified_user: ""
      training_id: training_id,
      name: "",
      version: "",
      status: "",
      vector_file_path: "",
      json_file_path: "",
      created_user: "",
      modified_user: "",
      modified_at: "",
      created_at: "",
      recommendation_questions: "",
      summary_content: ""
    }
    await axios
      .post("/training/remove-training-data", data, { headers: { Authorization: token } })
      .then((response) => {

        fetchData();

        toast.success(response.data.detail);

      })
      .catch((error) => {
        // add error notif
        toast.error(error.response.data.detail);

      });
  };
  //-------end remove---

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [formAddTraining, setFormAddTraining] = useState({
    training_id: "",
    name: "",
    version: "",
    status: "",
    vector_file_path: "",
    json_file_path: "",
    created_user: "",
    modified_user: "",
    created_at: "",
    modified_at: "",
    recommendation_questions: "",
    summary_content: ""
  })

  const onChangeForm = (label, event) => {
    switch (label) {
      case "name":
        setFormAddTraining({ ...formAddTraining, name: event.target.value });
        break;
      case "version":
        setFormAddTraining({ ...formAddTraining, version: event.target.value });
        break;
      default:
        break;
    }
  };

  const handleSaveChange = async (event) => {
    event.preventDefault();
    await axios
      .post("/training/add-training-data", formAddTraining, { headers: { Authorization: token } })
      .then((response) => {

        setOpen(false);
        fetchData();

        toast.success(response.data.detail);
        history('/training-data-detail?id=' + response.data.result.training_id);
      })
      .catch((error) => {
        // add error notif
        toast.error(error.response.data.detail);

      });
  };

  //----------end design dialog----------------------
  const [trainingData, setTrainingData] = React.useState([]);

  const auth_token = localStorage.getItem("auth_token");
  const auth_token_type = localStorage.getItem("auth_token_type");
  const token = auth_token_type + " " + auth_token;

  const fetchData = async () => {
    try {
      const res = await axios.get("/training/get-data-training-for-grid", {
        headers: { Authorization: token },
      });
      const trainingTemp = res.data.result.map((training, idx) => ({

        id: training.training_id,
        name: training.Tr_Name,
        version: training.version,
        status: training.status,
        vector_file_path: training.vector_file_path,
        json_file_path: training.json_file_path,
        // created_user: training.created_user,
        // modified_user: training.modified_user,
        created_user: training.createdUserName,
        modified_user: training.modifiedUserName,
        created_at: training.created_at,
        modified_at: training.modified_at,
        is_used: training.is_used
      }));
      setTrainingData(trainingTemp);
      // console.log("trainingData: ", trainingData)
    } catch (e) {
      console.error(e);
    }
  }

  // get data
  React.useEffect(() => {
    fetchData();
  }, [])

  const rows = trainingData;

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container style={{ maxWidth: '95%', margin: '20px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 0 10px 0' }}>
            <div className='csstext'>Training Data Management</div>
            <div style={{ paddingTop: '10px' }}>
              <Button onClick={handleClickOpen} variant="outlined" startIcon={<AddIcon />}>
                Add New
              </Button>
            </div>
          </div>
          <Box sx={{ height: '60vh', width: '95%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              checkboxSelection
              disableRowSelectionOnClick
            />
          </Box>
          {/*----ui dialog-------*/}
          <div>
            <Dialog open={open} onClose={handleClose} style={{ minWidth: '40%' }}>
              <DialogTitle>Add Training Data</DialogTitle>
              <Divider />

              <DialogContent>
                <DialogContentText>
                </DialogContentText>
                <label htmlFor="name" className='font_text' style={{ paddingBottom: 10 }}>Training Name</label>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                 
                  type="text"
                  fullWidth
                  
                  onChange={(event) => {
                    onChangeForm("name", event)
                  }}
                />
                <label htmlFor="version" className='font_text' style={{ paddingBottom: 10, paddingTop:10 }}>Training Version</label>
                <TextField
                  margin="dense"
                  id="version"
                  
                  type="text"
                  fullWidth
                 
                  onChange={(event) => {
                    onChangeForm("version", event)
                  }}
                />

              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSaveChange}>Save </Button>
              </DialogActions>
            </Dialog>
          </div>
          {/*-----end ui dialog------*/}
        </Container>
      </React.Fragment>

      {/*----ui dialog-for confirm delete------*/}
      <div>
        <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)} style={{ minWidth: '40%' }}>
          <DialogTitle>Confirmation</DialogTitle>
          <Divider />

          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete Training Data?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleDelete}>Delete</Button>
          </DialogActions>
        </Dialog>
      </div>
      {/*-----end ui dialog-for confirm delete-----*/}

    </>
  )
}

export default TrainingData