import { useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import React from 'react'
import { toast } from 'react-toastify';
import axios from './../configs'
import './register.css';
import PersonIcon from '@mui/icons-material/Person';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import Divider from '@mui/material/Divider';
import KeyIcon from '@mui/icons-material/Key';
import { Bolt } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';

const Profile = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    let bien = {}

    // Register Form
    const [formRegister, setFormRegister] = useState({
        name: "",
        username: "",
        email: "",
        phone_number: "",
        password: "",
        key: "",
        confirmpass: ""
    })

    // get info user

    const onSubmitGetInfoUser = async (event) => {

        const auth_token = localStorage.getItem("auth_token");
        const auth_token_type = localStorage.getItem("auth_token_type");
        const token = auth_token_type + " " + auth_token;
        //fetch data from get user api
        axios
            .get(`/users/getprofile`, {
                headers: { Authorization: token },
            }).then((response) => {

                setFormRegister({ ...response.data.result });

            })
            .catch((error) => {
                // add error notif            
                toast.error(error.message);

            });
    };

    const onChangeForm = (label, event) => {
        switch (label) {
            case "name":
                setFormRegister({ ...formRegister, name: event.target.value });
                break;
            case "username":
                setFormRegister({ ...formRegister, username: event.target.value });
                break;
            case "email":
                // email validation
                const email_validation = /\S+@\S+\.\S+/;
                if (email_validation.test(event.target.value)) {
                    setFormRegister({ ...formRegister, email: event.target.value });
                }
                break;
            case "phone_number":
                setFormRegister({ ...formRegister, phone_number: event.target.value });
                break;
            case "password":
                setFormRegister({ ...formRegister, password: event.target.value });
                break;
            case "key":
                setFormRegister({ ...formRegister, key: event.target.value });
                break;
            case "confirmpass":
                setFormRegister({ ...formRegister, confirmpass: event.target.value });
                break;
            default:
                break;
        }

    };

    React.useEffect(() => {
        onSubmitGetInfoUser();

    }, [])
    //   Submit handler
    const onSubmitHandler = async (event) => {
        debugger
        event.preventDefault();
        const auth_token = localStorage.getItem("auth_token");
        const auth_token_type = localStorage.getItem("auth_token_type");
        const token = auth_token_type + " " + auth_token;

        const data = {
            username: formRegister.username,
            email: formRegister.email,
            name: formRegister.name,
            password: formRegister.password,
            phone_number: formRegister.phone_number,
            key: formRegister.key,
            confirmpass: ""
        }
        await axios
            .post(`/users/updateinfoperson`, data, {
                headers: { Authorization: token },
            })
            .then((response) => {
                // move to sign in page
                navigate("/home");
                // add successfully notif              
                toast.success(response.data.detail);

            })
            .catch((error) => {
                // add error notif
                toast.error(error.response.data.detail);

            });
    };

    function ButtonCancle() {
        return navigate("/login");
    
      };
    

    return (

        <div className='profile_container'>
            <div style={{ paddingTop: 15, paddingBottom: 15 }}>
                <p className='login_header' style={{ padding: 0, margin: 0 }}>
                    Update Profile
                </p>

                <Divider style={{ marginLeft: 20, marginRight: 20, marginTop: 20 }} />
            </div>
            <form onSubmit={onSubmitHandler}>
                <div className='form_login' style={{ paddingLeft: 20, paddingRight: 20 }}>
                    {/* <div style={{ paddingBottom: 10 }}>
                        <label htmlFor="username" className='font_text' style={{ paddingBottom: 10 }}>User Name</label>                      
                        <TextField id="username" style={{ width: '100%', backgroundColor: 'white' }}
                            placeholder=" " disabled
                            value={formRegister.username}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" >
                                        <PersonIcon style={{ color: 'teal' }}></PersonIcon>
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(event) => {
                                onChangeForm("username", event)
                            }} />
                    </div> */}

                    <div style={{ paddingBottom: 10 }}>
                        <label htmlFor="email" className='font_text' style={{ paddingBottom: 10 }}>Email</label>

                        <TextField id="email" style={{ width: '100%', backgroundColor: 'white' }}
                            placeholder=" " disabled
                            value={formRegister.email}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" >
                                        <EmailIcon style={{ color: 'teal' }}></EmailIcon>
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(event) => {
                                onChangeForm("email", event)
                            }} />

                    </div>

                    <div style={{ paddingBottom: 10 }}>
                        <label htmlFor="name" className='font_text' style={{ paddingBottom: 10 }}>Full Name</label>

                        <TextField id="name" style={{ width: '100%', backgroundColor: 'white' }}
                            placeholder=""
                            value={formRegister.name}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" >
                                        <PersonIcon style={{ color: 'teal' }}></PersonIcon>
                                    </InputAdornment>
                                ),
                            }}

                            onChange={(event) => {
                                onChangeForm("name", event)
                            }} />
                    </div>

                    <div style={{ paddingBottom: 10 }}>
                        <label htmlFor="phone_number" className='font_text' style={{ paddingBottom: 10 }}>Phone Number</label>

                        <TextField id="phone_number" style={{ width: '100%', backgroundColor: 'white' }}
                            placeholder=" "
                            value={formRegister.phone_number}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" >
                                        <LocalPhoneIcon style={{ color: 'teal' }}></LocalPhoneIcon>
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(event) => {
                                onChangeForm("phone_number", event)
                            }} />


                    </div>

                    {/* <div style={{ paddingBottom: 20 }}>
                        <label htmlFor="key" className='font_text' style={{ paddingBottom: 10 }}>OpenAI API Key</label>

                        <TextField id="key" style={{ width: '100%', textAlign: 'left', backgroundColor: 'white' }}
                            placeholder=" " type="text" value={formRegister.key}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" >
                                        <KeyIcon style={{ color: 'teal' }}></KeyIcon>
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(event) => {
                                onChangeForm("key", event)
                            }} />
                    </div> */}

                    <div style={{ paddingBottom: 20 }}>
                        {/* <Button className='button_login' variant="contained" style={{ backgroundColor: 'teal' }}
                            type="submit">
                            <div role="status">
                                <span style={{ fontSize: '18px' }}>Save</span>
                            </div>
                        </Button> */}
                           
                            <Button className='button_setting' variant="outlined" style={{  marginTop: '20px', marginLeft:'15px' }}
                                type="submit">
                                <div role="status">
                                <span > Save</span>
                                </div>
                            </Button>
                            <Button onClick={ButtonCancle} className='button_cancle' variant="outlined" style={{  margin: '20px 0px' }}
                                >
                                <div role="status">
                                <span>Cancel</span>
                                </div>
                            </Button>
                    </div>
                </div>

            </form>
        </div>
    )
}

export default Profile
