import React, { useState } from "react";
import axios from './../configs'
import IconButton from '@mui/material/IconButton';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { Tooltip } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { toast } from "react-toastify";
import Utils from './../utils/utils';
import SectionListDialog from './../pages/SectionListDialog'

import TreeViewSection from './TreeViewSection';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useAppStore } from "../utils/appstate";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import EditIcon from '@mui/icons-material/Edit';
import EditNodeNameDialog from './EditNodeNameDialog';


const SectionNote = (props) => {
  const { onSelectedNode } = props;

  const [open, setOpen, onSubmited] = React.useState(false);
  const [sectionType, setSectionType] = React.useState('');
  const [currentSection, setCurrentSection] = React.useState('');
  const [sections, setSections] = React.useState([]);
  const [notes, setNotes] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [selectedNodeTree, setSelectedNodeTree] = React.useState({});
  const [selectedParentNodeTree, setSelectedParentNodeTree] = React.useState({});
  const [openEditNote, setOpenEditNote] = React.useState(false);
  const timer = React.useRef(null)

  const updateAppInfo = useAppStore((state) => state.updateAppInfo);
  const appInfo = useAppStore((state) => state.AppInfo);

  const auth_token = localStorage.getItem("auth_token");
  const auth_token_type = localStorage.getItem("auth_token_type");
  const token = auth_token_type + " " + auth_token;

  React.useEffect(() => {
    setSections([]);
    getAllSections();
  }, []);

  const getAllSections = async () => {

    try {
      const res = await axios.get("/section/get-list-sections-for-note-default", {
        headers: { Authorization: token }
      })
        .then(response => {

          setIsLoading(false);
          const resTemp = response.data.result;
          setSections(resTemp);
          const filteredSecs = resTemp.filter(item => item.parent_id === '');
          setNotes(filteredSecs);
          const currSec = filteredSecs.length > 0 ? filteredSecs[0] : {}
          console.log(currSec)
          setCurrentSection(currSec);
        });

    } catch (e) {
      console.error(e);
    }
  };

  const handleClickSection = (type) => {
    setSectionType(type);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [formAddSection, setFormAddSection] = useState({
    section_id: "",
    parent_id: "",
    user_id: "",
    name: "",
    content: "",
    reference: "",
    type: "",
    training_id: "",
    document_id: "",
    created_user: "",
    created_at: "",
    modified_at: "",
    order_id: 0
  })

  const onChangeForm = (label, event) => {
    switch (label) {
      case "name":
        setFormAddSection({ ...formAddSection, name: event.target.value });
        break;
      // case "version":
      //   setFormAddTraining({ ...formAddTraining, version: event.target.value });
      //   break;
      default:
        break;
    }
  };

  const handleSaveChange = async (event) => {
    event.preventDefault();
    await axios
      .post("/section/add-section-note", formAddSection, { headers: { Authorization: token } })
      .then((response) => {

        setOpen(false);
        //fetchData();

        toast.success(response.data.detail);
        getAllSections();
        // history('/training-data-detail?id=' + response.data.result.training_id);
      })
      .catch((error) => {
        // add error notif
        toast.error(error.response.data.detail);

      });
  };

  // generate docx
  const handleExport = async () => {
    // event.preventDefault();
    await axios
      .post("/section/generate-docx", appInfo.rootSection, { headers: { Authorization: token } })
      .then((response) => {

        getAllSections();

        toast.success(response.data.detail);

        // download -------
        let section_id = response.data.result;
        axios({
          url: `/section/download-file-section-export/${section_id}`,
          method: 'GET',
          responseType: 'blob', // important
          headers: { Authorization: token }
        }).then((response) => {
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);

          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', `${currentSection.name}.docx`); //or any other extension
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
        //-----------------

      })
      .catch((error) => {
        // add error notif
        toast.error(error.response.data.detail);

      });
  };

  // ---------confirm dialog------
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);

  const handleBtnExport = (section) => {

    setConfirmDialogOpen(true);
    setSelectedRow(section)
  };

  const handleConfirmDelete = () => {
    handleExport();
    setConfirmDialogOpen(false);
  }
  //-------------------------
  // get selected note from treeView
  const sendNoteToSectionNodeParent = (SelectedNode, parent) => {
    setSelectedNodeTree(SelectedNode);
    setSelectedParentNodeTree(parent);
    if (parent && parent.children) {
      const currentIndex = parent.children.indexOf(SelectedNode);
    }

    // currentIndex == 0 => dau hang
    // currentIndex ==  parent.children.length - 1 => cuoi hang
  }
  // move up node
  const handleBtnMoveUp = async (selectedNodeTree, currentSection) => {

    const currentIndex = selectedParentNodeTree.children.indexOf(selectedNodeTree);
    // curn
    if (currentIndex > 0) {
      const prevIndex = currentIndex - 1;
      [selectedParentNodeTree.children[currentIndex], selectedParentNodeTree.children[prevIndex]] = [selectedParentNodeTree.children[prevIndex], selectedParentNodeTree.children[currentIndex]];
      selectedParentNodeTree.children.forEach((child, index) => {
        child.order = index;
      });

      // ------------------------------------
      let appInfoData = { ...appInfo }
      appInfoData.rootSection = currentSection;
      console.log(appInfoData.rootSection)
      updateAppInfo(appInfoData);

      // update order to database      

      if (timer.current) clearTimeout(timer.current);

      timer.current = setTimeout(() => {
        updateOrderServer(selectedParentNodeTree);
      }, 1000)
      //--------------------------------------

    }
  }

  // move down node
  const handleBtnMoveDown = async (selectedNodeTree, currentSection) => {

    const currentIndex = selectedParentNodeTree.children.indexOf(selectedNodeTree);
    if (currentIndex < selectedParentNodeTree.children.length - 1) {
      const nextIndex = currentIndex + 1;
      [selectedParentNodeTree.children[currentIndex], selectedParentNodeTree.children[nextIndex]] = [selectedParentNodeTree.children[nextIndex], selectedParentNodeTree.children[currentIndex]];
      selectedParentNodeTree.children.forEach((child, index) => {
        child.order = index;
      });

      // rebuild tree---------------
      let appInfoData = { ...appInfo }
      appInfoData.rootSection = currentSection;
      console.log(appInfoData.rootSection)
      updateAppInfo(appInfoData);

      // update order to database


      if (timer.current) clearTimeout(timer.current);

      timer.current = setTimeout(() => {
        updateOrderServer(selectedParentNodeTree);
      }, 1000)

      //--------------------------------------

    }
  }

  function updateOrderServer(selectedParentNodeTree) {
    const reorderedSections = selectedParentNodeTree.children.map((sec, index) => ({
      section_id: sec.section_id,
      // name: sec.name,
      order_id: index + 1,
    }));

    axios
      .post("/section/update-order-for-list-notes", reorderedSections, { headers: { Authorization: token } })
      .then((response) => {

        //  toast.success(response.data.detail);

      })
      .catch((error) => {
        toast.error(error.response.data.detail);
      });
  }

  function handleEditNote() {
    setOpenEditNote(true);
  }

  return (
    <>
      {/* <div className="navPrompt" style={{ display: 'block' }}> */}
      <div style={{ display: 'block' }}>
        <div style={{ display: 'flex', flexFlow: 'column', background: 'white', borderRadius: '20px', padding: '10px' }}>
          <div style={{ display: 'flex' }}>
            <div style={{ paddingTop: '10px', color: 'black' }}> Note:</div>
            <div style={{ paddingTop: '10px', fontWeight: 'bold' }}>
              <span>{currentSection.name}</span>
            </div>

            {notes.length > 0 && <div>
              <Tooltip title="Select section">
                <div>
                  <IconButton onClick={() => {
                    handleClickSection('listSection')
                  }}
                    style={{
                      marginLeft: '10px',
                      color: '#0297a1',
                      cursor: 'pointer',
                    }}
                  >
                    <EventNoteIcon style={{ fontSize: '1.2em' }} />
                  </IconButton>
                </div>
              </Tooltip>
            </div>}

            <Tooltip title="Add new section">
              <div>
                <IconButton onClick={() => {
                  handleClickSection('addSection')
                }}
                  style={{
                    color: '#0297a1',
                    cursor: 'pointer',
                  }}
                >
                  <AddBoxIcon style={{ fontSize: '1.2em' }} />
                </IconButton>
              </div>
            </Tooltip>

            {currentSection && currentSection.name && <div>
              <Tooltip title="Generate Docx">
                <div>
                  <IconButton onClick={() => {
                    handleBtnExport(currentSection)
                  }}
                    style={{
                      color: '#0297a1',
                      cursor: 'pointer',
                    }}
                  >
                    <CloudDownloadIcon style={{ fontSize: '1.2em' }} />
                  </IconButton>
                </div>
              </Tooltip>
            </div>}

            {selectedNodeTree && selectedNodeTree.section_id && <div>
              <Tooltip title="Edit Note Name">
                <div>
                  <IconButton onClick={() => {
                    handleEditNote()
                  }}
                    style={{
                      color: '#0297a1',
                      cursor: 'pointer',
                      marginLeft: '5px'
                    }}
                  >
                    <EditIcon style={{ fontSize: '1.2em' }} />
                  </IconButton>
                </div>
              </Tooltip>
            </div>}

            {selectedNodeTree && selectedNodeTree.section_id && <div>
              <Tooltip title="Move Up">
                <div>
                  <IconButton onClick={() => {
                    handleBtnMoveUp(selectedNodeTree, currentSection)
                  }}
                    style={{
                      color: '#0297a1',
                      cursor: 'pointer',
                    }}
                  >
                    <ArrowUpwardIcon style={{ fontSize: '1.2em' }} />
                  </IconButton>
                </div>
              </Tooltip>
            </div>}

            {selectedNodeTree && selectedNodeTree.section_id && <div>
              <Tooltip title="Move Down">
                <div>
                  <IconButton onClick={() => {
                    handleBtnMoveDown(selectedNodeTree, currentSection)
                  }}
                    style={{
                      color: '#0297a1',
                      cursor: 'pointer',
                      marginLeft: '-20px'
                    }}
                  >
                    <ArrowDownwardIcon style={{ fontSize: '1.2em' }} />
                  </IconButton>
                </div>
              </Tooltip>
            </div>}

          </div>
        </div>

        {/*****Tree view section****** */}
        <div style={{ marginTop: '20px' }}>
          {sections.length > 0 && <TreeViewSection sendNoteToSectionNodeChildren={sendNoteToSectionNodeParent} reloadNotes={() => { setSections([]); setCurrentSection({}); getAllSections() }} onSubmited={onSelectedNode} rootSection={currentSection} sections={sections} />}
        </div>
        {/*****end tree view section****** */}

      </div>

      {/*----ui dialog-add section------*/}
      {sectionType == 'addSection' &&
        <div>
          <Dialog open={open} onClose={handleClose} style={{ minWidth: '60%' }}>
            <DialogTitle>Add Note</DialogTitle>
            <Divider />

            <DialogContent>
              <DialogContentText>
              </DialogContentText>
              <label htmlFor="notename" className='font_text' style={{ paddingBottom: 10 }}>Note Name</label>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                type="text"
                fullWidth
                onChange={(event) => {
                  onChangeForm("name", event)
                }}
              />
            </DialogContent>

            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleSaveChange}>Save </Button>

            </DialogActions>
          </Dialog>
        </div>
      }
      {/*-----end ui dialog-add section-----*/}

      {/*----ui dialog-for confirm delete------*/}
      <div>
        <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)} style={{ minWidth: '40%' }}>
          <DialogTitle>Confirmation</DialogTitle>
          <Divider />

          <DialogContent>
            <DialogContentText>
              Are you sure you want to generate current note to docx?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
            <Button onClick={() => handleConfirmDelete()}>Generate</Button>
          </DialogActions>
        </Dialog>
      </div>
      {/*-----end ui dialog-for confirm delete-----*/}

      {/* -----open dialog list section----- */}
      {sectionType === 'listSection' && <SectionListDialog open={open} onSubmited={(value) => { setOpen(false); setCurrentSection(value) }} onClose={() => { setOpen(false); }}></SectionListDialog>}
      {openEditNote && <EditNodeNameDialog open={openEditNote} selectedNodeTree={selectedNodeTree} onSubmited={() => { getAllSections(); setOpenEditNote(false); setSelectedNodeTree({}); }} onClose={() => { setOpenEditNote(false); }}></EditNodeNameDialog>}
      {/*-----end dialog list section------- */}

    </>
  );


};

export default SectionNote;
