import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Avatar, Checkbox, DialogActions, Divider, IconButton, InputAdornment, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, TextField } from '@mui/material';
import axios from '../configs';
import ArticleIcon from '@mui/icons-material/Article';
import { teal } from '@mui/material/colors';
import SearchIcon from '@mui/icons-material/Search';
import { toast } from 'react-toastify';
import UploadDocument from './UploadDocument';
import AddIcon from '@mui/icons-material/Add';
import LoadingData from '../components/LoadingData';

export default function TrainingAddDocument(props) {
    const { onClose, open, onSubmited } = props;
    const auth_token = localStorage.getItem("auth_token");
    const auth_token_type = localStorage.getItem("auth_token_type");
    const token = auth_token_type + " " + auth_token;
    const closeDialog = (e, reason) => {
        if (reason === 'escapeKeyDown') {
            return;
        }
        if (reason === 'backdropClick') {
            return;
        }
        onClose();
    };

    const [trainingDataDetail, setTrainingDataDetail] = React.useState([]);
    const [documents, setDocuments] = React.useState([]);
    const [documentsRawDB, setDocumentsRawDB] = React.useState([]);
    const [searchText, setSearchText] = React.useState("");
    const [checked, setChecked] = React.useState([]);
    const [openUpload, setOpenUpload] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);

    const handleToggle = (doc) => () => {
        // const currentIndex = checked.indexOf(value);
        doc.isChecked = !doc.isChecked;
        const newChecked = [...checked];

        // if (currentIndex === -1) {
        //     newChecked.push(value);
        // } else {
        //     newChecked.splice(currentIndex, 1);
        // }

        if (doc.isChecked) {
            newChecked.push(doc);
        } else {
            const index = newChecked.findIndex((item) => item.id === doc.id);
            if (index !== -1) {
                newChecked.splice(index, 1);
            }
        }

        setChecked(newChecked);

    };


    React.useEffect(() => {
        setDocuments([]);
        setTrainingDataDetail(props.trainingdocs);
        setSearchText('');
        onSearch('');
    }, [props.trainingdocs]);

    const handleSearchChange = (event) => {
        const searchTextValue = event.target.value;
        setSearchText(searchTextValue);
        setTimeout(() => {
            // do search logic here          
            if (searchTextValue) {
                onSearch(searchTextValue);
            } else {
                onSearch("");
            }
        }, 800);
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            onSearch(searchText);
        }
    };

    const handleSearch = (event) => {
        onSearch(searchText);
    }

    const onSearch = async (search_text) => {
        try {
            const res = await axios.get("/training/get-list-docs-for-search-by-training-id-keyword", {
                headers: { Authorization: token }, params: { training_id: props.id, key_search: search_text }
            })
                .then(response => {
                    setIsLoading(false);
                    const resTemp = response.data.result;
                    const newChecked = [...checked];
                    const updatedResTemp = resTemp.map((item) => {
                        item.isChecked = false;
                        const index = newUploadData.findIndex((doc) => doc.id === item.id);
                        if (index !== -1) {
                            newChecked.push(item);
                            return { ...item, isChecked: true };
                        }
                        return item;
                    });
                    setChecked(newChecked);
                    setDocuments(updatedResTemp);
                });

        } catch (e) {
            console.error(e);
        }
    };

    // check new documents upload
    let newUploadData = [];
    const handleUploadDoc = (data) => {
        newUploadData = data.data.result.files;
    }
    //----------

    let addDocumentTemp = [];

    const handleSaveChangeAdd = async (event) => {
        event.preventDefault();
        checked.map((doc) => {
            addDocumentTemp.push({
                training_detail_id: "",
                training_id: props.id,
                document_id: doc.id,
                created_user: "",
                modified_user: "",
                type: props.type
            });
        });

        const formAddTrainingDetail = { data: addDocumentTemp };

        await axios
            .post("/training/add-training-data-detail", formAddTrainingDetail, { headers: { Authorization: token } })
            .then((response) => {
                onSubmited(response);
                toast.success(response.data.detail);

            })
            .catch((error) => {
                // add error notif
                toast.error(error.response.data.detail);

            });
    };

    return (

        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={open}
            onClose={closeDialog}>
            <DialogTitle>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>  Add Documents</div>
                    <div>
                        <Button onClick={() => { setOpenUpload(true) }} variant="outlined" startIcon={<AddIcon />}>
                            Upload New
                        </Button>
                    </div>
                </div>
            </DialogTitle>
            <Divider />
            <UploadDocument
                open={openUpload}
                onClose={() => { setOpenUpload(false) }}
                onSubmit={() => { onSearch(searchText); setOpenUpload(false); }}
                onUploadDoc={handleUploadDoc}></UploadDocument>
            <div style={{ width: '100%', padding: '10px' }}>
                <TextField
                    label="Search"
                    variant="outlined"
                    value={searchText}
                    onChange={handleSearchChange}
                    onKeyPress={handleKeyPress}
                    style={{ width: '100%' }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleSearch}>
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>,
                    }}
                />

            </div>
            <DialogContent>
                <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {isLoading ? (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <LoadingData />
                        </div>
                    ) : (documents.length > 0 ? documents.map((doc) => {
                        const documentId = doc.id;
                        return (
                            <div key={doc.id}>
                                <ListItem
                                    // key={doc.id}
                                    secondaryAction={
                                        <Checkbox
                                            edge="end"
                                            onChange={handleToggle(doc)}
                                            // checked={checked.indexOf(doc) !== -1}
                                            checked={doc.isChecked}
                                            inputProps={{ 'aria-labelledby': documentId }}
                                        />
                                    }
                                    disablePadding
                                >
                                    <ListItemButton>
                                        <ListItemAvatar>
                                            <Avatar sx={{ bgcolor: teal[100], color: teal[600] }}>
                                                <ArticleIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText id={doc.id} primary={doc.filename} secondary={`Type: ${doc.type}`} />
                                    </ListItemButton>
                                </ListItem>
                                <Divider light />
                            </div>
                        );
                    }) : <h5>No data to display</h5>)}

                </List>
                {/* ------ end list check box -------*/}
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>Cancel</Button>
                <Button onClick={handleSaveChangeAdd}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}