import { useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import React from 'react'
import { toast } from 'react-toastify';
import axios from '../configs'
import './register.css';
import PersonIcon from '@mui/icons-material/Person';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import InputAdornment from '@mui/material/InputAdornment';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import Divider from '@mui/material/Divider';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import ViewPdfDocument from '../components/ViewDocument';

export default function DetailReference(props) {
    const navigate = useNavigate();
    const { onClose, open, onSubmited } = props;
    const auth_token = localStorage.getItem("auth_token");
    const auth_token_type = localStorage.getItem("auth_token_type");
    const token = auth_token_type + " " + auth_token;
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [openViewPdf, setOpenViewPdf] = React.useState(false);
    const [selectedDocument, setSelectedDocument] = useState({});

    const closeDialog = (e, reason) => {
        if (reason === 'escapeKeyDown') {
            return;
        }
        if (reason === 'backdropClick') {
            return;
        }
        onClose();
    };
    // view document
    const handleDocumentClick = (selectedDoc) => {
        debugger
        console.log("Huong - props: ", props)
        setSelectedDocument(selectedDoc);
        setOpenViewPdf(true);
    };

    return (


        <Dialog
            disableEscapeKeyDown={true}
            fullWidth={true}
            maxWidth={"sm"}
            onClose={closeDialog} aria-labelledby="add-dialog-title" open={props.open}
        >
            <DialogTitle style={{ fontSize: '17px' }}>
                <span style={{ textAlign: 'left' }}>
                    {props.selectedValue.metadata.documentname} - page: {props.selectedValue.metadata.page}
                </span>
                <Button onClick={() => handleDocumentClick(props.selectedValue.metadata)} style={{ float: 'right' }}>View Document</Button>
            </DialogTitle>
            
            <Divider />

            <DialogContent>
             
                <DialogContentText id="alert-dialog-slide-description">
                    {props.selectedValue.page_content}
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={closeDialog} style={{ marginRight: '20px', marginBottom: '15px' }}>Close</Button>
            </DialogActions>

            <div>
                {openViewPdf ? <ViewPdfDocument open={openViewPdf} filename={selectedDocument.source} id={selectedDocument.document_id} page={selectedDocument.page} onClose={() => { setOpenViewPdf(false); }}></ViewPdfDocument> : <></>}
            </div>

        </Dialog>



    )
}


