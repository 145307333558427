import { useNavigate, Link } from "react-router-dom"
import { useState } from "react";
import { toast } from "react-toastify";
import axios from '../configs';
import * as React from 'react';
import './register.css';
import '../App.css'
import PersonIcon from '@mui/icons-material/Person';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import Grid from '@mui/material/Unstable_Grid2';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import KeyIcon from '@mui/icons-material/Key';
import EditIcon from '@mui/icons-material/Edit';

const UserDetail = () => {
  const history = useNavigate();
  const navigate = useNavigate();
  const auth_token = localStorage.getItem("auth_token");
  const auth_token_type = localStorage.getItem("auth_token_type");
  const token = auth_token_type + " " + auth_token;
  const query = new URLSearchParams(window.location.search);
  const user_id = query.get('userid');
  //const [value, setValue]= React.useState();
  const [currentRole, setCurrentRole] = React.useState({
    currentRoleId: "",
    //currentRoleName: ""
  })
  //const [checked, setChecked] = React.useState([]);
  const [roles, setRoles] = React.useState([]);
  const [formRegister, setFormRegister] = useState({
    name: "",
    username: "",
    email: "",
    role_name: "",
    id: "",
    roleid: "",
    phone_number: "",
    password: "",
    key: ""

  })


  let listRole = [];
  const getallRole = async () => {
    axios.get(`/users/get_all_role_user`, {
      headers: { Authorization: token },
    }).then((response) => {
      // console.log("role",response.data.result);
      response.data.result.map((item) => {
        listRole.push({
          id: item.id,
          role_name: item.role_name
        })
      }
      );


      setRoles(listRole);
      toast.success(response.data.result);
    })
      .catch((error) => {
        console.error(error);
      })
  };


  const onSubmitGetInfoUser = async () => {
    axios.get(`/users/get_user_detail_by_id`, {
      headers: { Authorization: token }, params: { userid: user_id }
    }).then((response) => {
      //console.log("res",response);
      setFormRegister({ ...response.data.result });

      const item = {
        currentRoleId: response.data.result.roleid,
        //currentRoleName: response.data.result.role_name
      }
      setCurrentRole(item);

    })
      .catch((error) => {
        console.error(error);
      })
  };


  React.useEffect(() => {
    onSubmitGetInfoUser();
    getallRole();
  }, []);

  const onChangeForm = (label, event) => {
    switch (label) {
      case "name":
        setFormRegister({ ...formRegister, name: event.target.value });
        break;
      case "username":
        setFormRegister({ ...formRegister, username: event.target.value });
        break;
      case "email":
        // email validation
        const email_validation = /\S+@\S+\.\S+/;
        if (email_validation.test(event.target.value)) {
          setFormRegister({ ...formRegister, email: event.target.value });
        }
        break;
      case "phone_number":
        setFormRegister({ ...formRegister, phone_number: event.target.value });
        break;
      case "password":
        setFormRegister({ ...formRegister, password: event.target.value });
        break;
      case "key":
        setFormRegister({ ...formRegister, key: event.target.value });
        break;
      case "role_name":
        setFormRegister({ ...formRegister, role_name: event.target.value });
        break;
      default:
        break;
    }

  };



  const onSubmitHandler = async (event) => {
    event.preventDefault();

    await axios
      .post(`/users/update-user`, formRegister, {
        headers: { Authorization: token },
      })
      .then((response) => {

        navigate("/user-management");

        console.log(response)
        toast.success(response.data.detail);

      })
      .catch((error) => {
        // add error notif
        toast.error(error.response.data.detail);

      });

  }

  function ButtonCancle() {
    return navigate("/user-management");

  };


  const handleChange = (event) => {
    console.log("event.target.value", event.target.value);
    const item = {
      currentRoleId: event.target.value

    }
    setCurrentRole(item);
    const role = roles.find(x => x.id === event.target.value);
    formRegister.roleid = event.target.value;
    formRegister.role_name = role.role_name;

  };

  return (
    <>
      <div className='user_detail_container'>
        <div style={{ paddingTop: 15, paddingBottom: 15 }}>
          <p className='login_header' style={{ padding: 0, margin: 0 }}>
            User Detail
          </p>

          <Divider style={{ marginLeft: 20, marginRight: 20, marginTop: 20 }} />
        </div>
      </div>
      <div className='user_detail_container'>
        <form onSubmit={onSubmitHandler}>
          <div className='form_login' style={{ paddingLeft: 20, paddingRight: 20 }}>

            <Accordion >
              <AccordionDetails>
                  <Grid container spacing={2} columns={16}>
                    <Grid xs={8}>
                      <div style={{ paddingBottom: 10 }}>
                        <label htmlFor="username" className='font_text' style={{ paddingBottom: 10 }}>User Name</label>

                        <TextField id="username" style={{ width: '100%', backgroundColor: 'white' }}
                          placeholder=" " disabled
                          value={formRegister.username}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start" >
                                <PersonIcon style={{ color: 'teal' }}></PersonIcon>
                              </InputAdornment>
                            ),
                          }}
                          onChange={(event) => {
                            onChangeForm("username", event)
                          }} />


                      </div>
                    </Grid>
                    <Grid xs={8}>
                      <div style={{ paddingBottom: 10 }}>
                        <label htmlFor="email" className='font_text' style={{ paddingBottom: 10 }}>Email</label>
                        <TextField id="email" style={{ width: '100%', backgroundColor: 'white' }}
                          placeholder=" " disabled
                          value={formRegister.email}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start" >
                                <EmailIcon style={{ color: 'teal' }}></EmailIcon>
                              </InputAdornment>
                            ),
                          }}
                          onChange={(event) => {
                            onChangeForm("email", event)
                          }} />

                      </div>
                    </Grid>

                  </Grid>
              </AccordionDetails>
            </Accordion>

            {/* -----------------row 2 ----------------*/}

            <Accordion >
              <AccordionDetails>
                  <Grid container spacing={2} columns={16}>
                    <Grid xs={8}>
                      <div style={{ paddingBottom: 10 }}>
                        <label htmlFor="name" className='font_text' style={{ paddingBottom: 10 }}>Name</label>


                        <TextField id="name" style={{ width: '100%', backgroundColor: 'white' }}
                          placeholder=""
                          value={formRegister.name}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start" >
                                <PersonIcon style={{ color: 'teal' }}></PersonIcon>
                              </InputAdornment>
                            ),
                          }}

                          onChange={(event) => {
                            onChangeForm("name", event)
                          }} />


                      </div>
                    </Grid>
                    <Grid xs={8}>
                      <div style={{ paddingBottom: 10 }}>
                        <label htmlFor="phone_number" className='font_text' style={{ paddingBottom: 10 }}>Phone Number</label>


                        <TextField id="phone_number" style={{ width: '100%', backgroundColor: 'white' }}
                          placeholder=" "
                          value={formRegister.phone_number}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start" >
                                <LocalPhoneIcon style={{ color: 'teal' }}></LocalPhoneIcon>
                              </InputAdornment>
                            ),
                          }}
                          onChange={(event) => {
                            onChangeForm("phone_number", event)
                          }} />
                      </div>
                    </Grid>
                  </Grid>
              </AccordionDetails>
            </Accordion>

            {/* -----------------row 3--------------------- */}

            <Accordion >
              <AccordionDetails>
                  <Grid container spacing={3} columns={16}>
                    <Grid xs={8}>
                      <div style={{ paddingBottom: 10 }}>
                        <label htmlFor="key" className='font_text' style={{ paddingBottom: 10 }}>OpenAI API Key</label>

                        <TextField id="key" style={{ width: '100%', textAlign: 'left', backgroundColor: 'white' }}
                          placeholder=" " type="text" value={formRegister.key}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start" >
                                <KeyIcon style={{ color: 'teal' }}></KeyIcon>
                              </InputAdornment>
                            ),
                          }}
                          onChange={(event) => {
                            onChangeForm("key", event)
                          }} />

                      </div>
                    </Grid>
                    <Grid xs={7}>
                      <div style={{ paddingBottom: 10 }}>
                        <label htmlFor="role_name" className='font_text' style={{ paddingBottom: 10 }}>Role Name</label>


                        <TextField id="role_name" style={{ width: '100%', backgroundColor: 'white' }}
                          placeholder=" " disabled
                          value={formRegister.role_name}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start" >
                                <LocalPhoneIcon style={{ color: 'teal' }}></LocalPhoneIcon>
                              </InputAdornment>
                            ),
                          }}
                          onChange={(event) => {
                            onChangeForm("role_name", event)
                          }} />

                      </div>
                    </Grid>

                    <Grid xs={1} style={{ marginLeft: -20 }}>
                      <div style={{ marginTop: 25 }}>

                        <Button onClick={(event) => {
                          history('/update-role-user?userid=' + encodeURIComponent(formRegister.id));
                        }} style={{ height: '55px' }} startIcon={<EditIcon />}>

                        </Button>

                      </div>
                    </Grid>

                  </Grid>
              </AccordionDetails>
            </Accordion>

            <div style={{ paddingBottom: 20 }}>
              <Button className='button_setting' variant="outlined" style={{  marginTop: '20px', marginLeft:'15px' }}
                type="submit">
                <div role="status">
                  <span>Save</span>
                </div>
              </Button>
              <Button onClick={ButtonCancle} className='button_cancle' variant="outlined" style={{  margin: '20px 0px' }}
              >
                <div role="status">
                  <span>Cancel</span>
                </div>
              </Button>
              
            </div>
          </div>

        </form>
      </div>


    </>
  )

}
export default UserDetail