import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogActions, Divider, SvgIcon, TextField } from '@mui/material';
import axios from '../configs'
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import DialogContentText from '@mui/material/DialogContentText';
import { toast } from 'react-toastify';
import { Link, useNavigate } from "react-router-dom";
import { useState } from 'react'
import { IconButton, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import AddUser from './AddUser';
import Utils from "../utils/utils";

const User_Management = () => {
  //const { onClose } = props;
  const history = useNavigate();
  const navigate = useNavigate();


  const columns = [

    {
      field: 'username',
      headerName: 'User Name',
      width: 300,
      editable: true,
    },

    {
      field: 'email',
      headerName: 'Email',
      width: 300,
      editable: true,
    },

    {
      field: 'role_name',
      headerName: 'Role',
      width: 200,
      editable: true,
    }
    ,
    {
      field: 'Actions',
      headerName: 'Actions', width: 200,
      renderCell: (params) => (
        <>
          <Tooltip title="Edit User">
            <IconButton
              aria-label="Edit User" size="large"
              onClick={(event) => {
                history('/user-detail?userid=' + encodeURIComponent(params.row.id));
              }}
              style={{ padding: 10 }}
            >
              <EditIcon style={{ color: 'teal' }} />

            </IconButton>
          </Tooltip>

          <Tooltip title="Delete User">
            <span>
              <IconButton
                aria-label="Delete" size="large"
                disabled={!validateButtonDelete(params.row.username)}
                onClick={(event) => {
                  setSelectedRow(params.row.id);
                  setConfirmDialogOpen(true);
                }}
                style={{ padding: 10 }}

              >
                {changeColorButton(params.row.id)}
                {/* <DeleteOutlineOutlinedIcon style={{ color: 'red' }} /> */}

              </IconButton>
            </span>
          </Tooltip>




        </>
      ),
    }
  ];


  const [userData, setUserData] = React.useState([]);
  const [infoLoginUsser, setInfoLoginUser] = useState({
    name: "",
    username: "",
    email: "",
    phone_number: "",
    password: "",
    key: "",
    confirmpass: ""
  })
  const auth_token = localStorage.getItem("auth_token");
  const auth_token_type = localStorage.getItem("auth_token_type");
  const token = auth_token_type + " " + auth_token;
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState([]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    fetchData();
  };
  let user = Utils.parseJwt(auth_token);
  // get info user login

  const getInfoUser = async (event) => {

    axios
      .get(`/users/getprofile`, {
        headers: { Authorization: token },
      }).then((response) => {

        setInfoLoginUser({ ...response.data.result });

      })
      .catch((error) => {
        //add error notif            
        toast.error(error.message);

      });
  };



  function ButtonCancle() {
    return navigate("/home");
  };

  const fetchData = async () => {
    try {
      await axios.get(`/users/getalluser`, {
        headers: { Authorization: token },
      })
        .then((response) => {
          const ListUserData = response.data.result.map((item) => ({
            roleid: item.roleid,
            email: item.email,
            name: item.name,
            role_name: item.role_name,
            username: item.username,
            password: item.password,
            id: item.id

          }));
          setUserData(ListUserData);

          toast.success(response.data.result);


        })
    } catch (e) {
      console.error(e);
    }
  }
  const rows = userData;
  const [selectedRow, setSelectedRow] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const handleDelete = () => {
    removeUser(selectedRow);
    setConfirmDialogOpen(false);
  };

  // remove user
  const removeUser = async (userid) => {
    // event.preventDefault();
    const data = {

      id: userid,
      name: "",
      username: "",
      email: "",
      role_name: "",
      roleid: ""
    }
    await axios
      .post("/users/remove-user-byid", data, { headers: { Authorization: token } })
      .then((response) => {

        fetchData();

        toast.success(response.data.detail);

      })
      .catch((error) => {
        // add error notif
        toast.error(error.response.data.detail);

      });
  };
  // get data
  React.useEffect(() => {
    fetchData();
    getInfoUser();

  }, [])


  //check button delete
  function validateButtonDelete(item) {

    if (infoLoginUsser.username === item) {
      return false;
    } else {
      return true;
    }


  }
  function changeColorButton(item) {

    if (item === user.id) {
      return <DeleteOutlineOutlinedIcon style={{ color: 'grown' }} />;
    } else {
      return <DeleteOutlineOutlinedIcon style={{ color: 'red' }} />;
    }

  }

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container style={{ maxWidth: '95%', margin: '20px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 0 10px 0' }}>
            <div className='csstext'>User Management</div>
            <div style={{ paddingTop: '10px' }}>
              <Button onClick={handleClickOpen} variant="outlined" startIcon={<AddIcon />}>
                Add New
              </Button>
              {/*----ui dialog-for add new------*/}
              <AddUser
                selectedValue={selectedValue}
                open={open}
                onClose={handleClose}
              />
            </div>
          </div>
          <Box sx={{ height: '60vh', width: '95%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              checkboxSelection
              disableRowSelectionOnClick
            />
          </Box>

        </Container>
      </React.Fragment>



      {/*----ui dialog-for confirm delete------*/}
      <div>
        <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)} style={{ minWidth: '40%' }}>
          <DialogTitle>Confirmation</DialogTitle>
          <Divider />

          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete User?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleDelete}>Delete</Button>
          </DialogActions>
        </Dialog>
      </div>
      {/*-----end ui dialog-for confirm delete-----*/}

    </>
  )

}
export default User_Management
