import { Link, useNavigate, } from "react-router-dom"
import { useState, useRef } from "react";
import { toast } from "react-toastify";
import { useAuth } from "../utils/auth"
import axios from '../configs'
import React from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function About(props) {
  const navigate = useNavigate();
  const { onClose, open, onSubmited } = props;
  const closeDialog = (e, reason) => {
    if (reason === 'escapeKeyDown') {
        return;
    }
    if (reason === 'backdropClick') {
        return;
    }
    onClose();
};
    function ButtonCancel() {
      return navigate("/home");
    };

   
  return (
    // <div className='profile_container'>
    //        <div style={{ paddingTop: 15, paddingBottom: 15 }}>
    //             <p className='login_header' style={{ padding: 0, margin: 0 }}>
                  
    //             <HelpOutlineIcon style={{padding:'0px'}}></HelpOutlineIcon>  About 
                
    //             </p>

    //             <Divider style={{ marginLeft: 20, marginRight: 20, marginTop: 20 }} />
    //         </div>
    //       <div className='form_login' style={{ paddingLeft: 20, paddingRight: 20 }} >

    //         <Grid container spacing={1} style={{marginLeft:'10px', marginTop:'-25px'}}>
    //           <Grid item xs={12}>
    //             <div >
    //               <h2>
    //                 Phamiliar.AI
    //               </h2>
    //               <p style={{marginTop:'-20px'}}>
    //               Version 1.0.3 (Official build)
    //               </p>
    //               <br/>
                  

    //             </div>
                
    //           </Grid>
              

    //         </Grid>
    //         <Grid style={{marginLeft:'10px'}}>
    //           <div>
    //               {'Copyright © '}
    //                 <Link color="inherit" href={"https://phamiliar.ai"} target="_blank">
    //                   Phamiliar.AI
    //                 </Link>{' '}
    //                 {new Date().getFullYear()}
    //                 {'.'}

                    
    //           </div>
    //           <div>
    //           <Link color="inherit" href={"https://phamiliar.ai"} target="_blank">
    //                   Terms and Conditions
    //                 </Link>
    //           </div>
    //         </Grid>


    //     </div>
    //     <div style={{ textAlign: 'right', padding: '20px' }}>
    //                     <Button size="medium"variant="outlined"style={{ maxWidth: '200px', minWidth: '100px' }} onClick={ButtonCancel}>
    //                         Close
    //                     </Button>
    //     </div>
    // </div>
    
    <Dialog
            disableEscapeKeyDown={true}
            fullWidth={true}
            maxWidth={"sm"}
            onClose={closeDialog} aria-labelledby="add-dialog-title" open={props.open}
        >
           
            <DialogContent>
             <div className='profile_container'>
               <div style={{ paddingTop: 15, paddingBottom: 15 }}>
                 <p className='login_header' style={{ padding: 0, margin: 0 }}>
                  
                <HelpOutlineIcon style={{padding:'0px'}}></HelpOutlineIcon>  About 
                
                 </p>

                 <Divider style={{ marginLeft: 20, marginRight: 20, marginTop: 20 }} />
             </div>
           <div className='form_login' style={{ paddingLeft: 20, paddingRight: 20 }} >

             <Grid container spacing={1} style={{marginLeft:'10px', marginTop:'-25px'}}>
               <Grid item xs={12}>
                 <div >
                   <h2>
                     Phamiliar.AI
                   </h2>
                   <p style={{marginTop:'-20px'}}>
                   Version 1.0.3 (Official build)
                   </p>
                   <br/>
                  

                 </div>
                
               </Grid>
              

             </Grid>
             <Grid style={{marginLeft:'10px'}}>
               <div>
                   {'Copyright © '}
                     <Link color="inherit" href={"https://phamiliar.ai"} target="_blank">
                       Phamiliar.AI
                     </Link>{' '}
                     {new Date().getFullYear()}
                     {'.'}

                    
               </div>
               <div>
               <Link color="inherit" href={"https://phamiliar.ai"} target="_blank">
                       Terms and Conditions
                     </Link>
               </div>
             </Grid>


         </div>
            {/* <div style={{ textAlign: 'right', padding: '20px' }}>
                            <Button size="medium"variant="outlined"style={{ maxWidth: '200px', minWidth: '100px' }} onClick={ButtonCancel}>
                                Close
                            </Button>
            </div> */}
     </div>
            </DialogContent>
            <DialogActions>
                <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                    <Button onClick={closeDialog} >Cancel</Button>
                    
                </div>
                
               
            </DialogActions>
        </Dialog>


    );

}