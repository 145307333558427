import { useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import React from 'react'
import { toast } from 'react-toastify';
import axios from '../configs'
import './register.css';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Divider from '@mui/material/Divider';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import LockIcon from '@mui/icons-material/Lock';
import { useSearchParams } from 'react-router-dom';

const ChangePassword = () => {
    const navigate = useNavigate();
    const auth_token = localStorage.getItem("auth_token");
    const auth_token_type = localStorage.getItem("auth_token_type");
    const token = auth_token_type + " " + auth_token;
    
    

    // Register Form
    const [formRegister, setFormRegister] = useState({
        name: "",
        username: "",
        email: "",
        phone_number: "",
        password: "",
        key: "",
        confirmpass:""
    })

    // get info user

    const onSubmitGetInfoUser = async () => {
      
        //fetch data from get user api
        try {
            await axios.get(`/users/getprofile`,{
            headers: { Authorization: token },
        })
        .then((response) => {
            console.log(response.data.result);
            let ListUserData = response.data.result.map((item) => ({
                            phone_number: item.phone_number,
                            email: item.email,
                            name: item.name,
                            key: item.key,
                            username: item.username,
                            password: "",
                            confirmpass: ""
                    
                }));
            setFormRegister(ListUserData);
            toast.success(response.data.result);


      })
      } catch (e) {
        //console.error("error",e);
      }
        
    }

        
    const onChangeForm = (label, event) => {
        switch (label) {
            case "name":
                setFormRegister({ ...formRegister, name: event.target.value });
                break;
            case "username":
                setFormRegister({ ...formRegister, username: event.target.value });
                break;
            case "email":
                // email validation
                const email_validation = /\S+@\S+\.\S+/;
                if (email_validation.test(event.target.value)) {
                    setFormRegister({ ...formRegister, email: event.target.value });
                }
                break;
            case "phone_number":
                setFormRegister({ ...formRegister, phone_number: event.target.value });
                break;
            case "password":
                setFormRegister({ ...formRegister, password: event.target.value });
                break;
            case "key":
                setFormRegister({ ...formRegister, key: event.target.value });
                break;
            case "confirmpass":
                setFormRegister({ ...formRegister, confirmpass: event.target.value });
                break;
            default:
                break;
        }

    };

    React.useEffect(() => {
        onSubmitGetInfoUser();

    }, [])

       

    function validateForm() {
        if ( formRegister.password.length > 0 && formRegister.confirmpass.length >0){
            return true;
            }
            
            return false;
        
       
    }
    //   Submit handler
    const onSubmitHandler = async (event) => {
        event.preventDefault();
        const auth_token = localStorage.getItem("auth_token");
        const auth_token_type = localStorage.getItem("auth_token_type");
        const token = auth_token_type + " " + auth_token;
        //const token = localStorage.get('token')
        //console.log(token)
        // Post to register API\
        
        await axios
            .post(`/users/changepass`, formRegister, {
                headers: { Authorization: token },
            })
            .then((response) => {
                // move to sign in page
                navigate("/home");
                // add successfully notif              
                toast.success(response.data.detail);

            })
            .catch((error) => {
                // add error notif
                toast.error(error.response.data.detail);

            }); 
      
       
    };
    
    function ButtonCancle() {
        return navigate("/home");
      };

    return (

        <div className='profile_container'>
            <div style={{ paddingTop: 15, paddingBottom: 15 }}>
                <p className='login_header' style={{ padding: 0, margin: 0 }}>
                    Change Password
                </p>

                <Divider style={{ marginLeft: 20, marginRight: 20, marginTop: 20 }} />
            </div>
            <form onSubmit={onSubmitHandler}>
                <div className='form_login' style={{ paddingLeft: 20, paddingRight: 20 }}>


                <div style={{ paddingBottom: 20 }}>
                        <label htmlFor="password" className='font_text' style={{ paddingBottom: 10 }}>Password</label>

                        <TextField id="password" style={{ width: '100%', textAlign: 'left', backgroundColor: 'white' }}
                            placeholder=" " type="password"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" >
                                        <LockIcon style={{ color: 'teal' }}></LockIcon>
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(event) => {
                                onChangeForm("password", event)
                            }} />

                    </div>
                    <div style={{ paddingBottom: 20 }}>
                        <label htmlFor="confirmpass" className='font_text' style={{ paddingBottom: 10 }}>Confirm Password</label>

                        <TextField id="confirmpass" style={{ width: '100%', textAlign: 'left', backgroundColor: 'white' }}
                            placeholder=" " type="password"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" >
                                        <CheckOutlinedIcon style={{ color: 'teal' }}></CheckOutlinedIcon>
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(event) => {
                                onChangeForm("confirmpass", event)
                            }} />

                    </div>   
                    <div style={{ paddingBottom: 20 }} >
                      
                        <Button disabled={!validateForm()} onClick={onSubmitHandler} className='button_setting' variant="outlined" style={{  marginTop: '20px', marginLeft:'15px' }}>
                          
                            <div role="status">
                                <span>Save</span>
                            </div>
                        </Button>

                        <Button onClick={ButtonCancle}  className='button_cancle' variant="outlined" style={{  margin: '20px 0px' }}
                        >
                            <div role="status">
                                <span>Cancel</span>
                            </div>
                        </Button>
                    </div>
                </div>

            </form>
        </div>
    )
}

export default ChangePassword
