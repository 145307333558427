import { Avatar, Button } from "@mui/material";
import React from "react";
import Utils from "../utils/utils";
import Box from '@mui/material/Box';
const ExternalLogin = ({ props }) => {
  const googleLogin = () => {
    // var auth_provider = "google-oidc"
    // var login_url = props.producerLoginRedirectEndpoint + "?auth_provider=" + auth_provider
    // window.location.href = login_url
    window.location.href = `${Utils.ApiEndpoint}/auth/google/login`
  }

  const azureLogin = () => {
    // var auth_provider = "azure-oidc"
    // var login_url = props.producerLoginRedirectEndpoint + "?auth_provider=" + auth_provider
    // window.location.href = login_url
    window.location.href = `${Utils.ApiEndpoint}/auth/microsoft/login`
    //window.location.replace("/auth/microsoft/login")
  }

    
    const authenticate = () => {
      var authToken = (window.location.search.match(/authToken=([^&]+)/) || [])[1]
      if(authToken){
        localStorage.setItem("auth_token", authToken);
        localStorage.setItem("auth_token_type", "Bearer");
        window.location.replace("/")
        }

    }


      React.useEffect(() => {
        authenticate();
    }, [])


  return (
    <section>
      <Box  noValidate sx={{ mt: 1 }}>
        <div style={{height:'50%'}}>
        <Button onClick={googleLogin} variant="outlined" fullWidth startIcon={<Avatar style={{marginLeft:'-13px'}} variant="square" alt="Google" src="/google.svg" />}>
          <span style={{textTransform: 'capitalize'}}>Sign in with Google</span> 
        </Button>
      </div>
      {/* <div style={{padding: '15px'}}> */}
      <div style={{paddingTop:'15px', paddingBottom:'15px',height:'50%'}}>
        <Button onClick={azureLogin}fullWidth variant="outlined" startIcon={<Avatar variant="square" alt="Microsoft" src="/static/microsoft.svg" />}>
         <span style={{textTransform: 'initial'}}>Sign in with Microsoft</span> 
        </Button>
      </div>
      </Box>
      
    </section>
  );
};

export default ExternalLogin;
