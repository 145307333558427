import React from "react";
import { AuthProvider, useAuth } from "../utils/auth";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Copyright from "./CopyRight";
import { RequireAuth } from "../utils/RequireAuth";
import { useAppStore } from "../utils/appstate";
import Utils from "../utils/utils";
import { useNavigate } from "react-router-dom"
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import { toast } from "react-toastify";
import FolderZipIcon from '@mui/icons-material/FolderZip';
import TrainingMultiFileDocument from '../pages/TrainingMultiFileDocument';
import CloseIcon from '@mui/icons-material/Close';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import About from '../pages/About';

function MainLayout({ children }) {

  const appInfo = useAppStore((state) => state.AppInfo);
  const navigate = useNavigate();
  const updateAppInfo = useAppStore((state) => state.updateAppInfo);
  const auth = useAuth();

  const theme = createTheme({
    palette: {
      primary: {
        // light: '#757ce8',
        main: '#016c74',
        // dark: '#002884',
        // contrastText: '#fff',
      },
      secondary: {
        // light: '#ff7961',
        main: '#016c74',
        // dark: '#ba000d',
        // contrastText: '#000',
      },
    },
  });

  const auth_token = localStorage.getItem("auth_token");
  let user = Utils.parseJwt(auth_token);
  //open dialog About
  const [openAbout, setOpenAbout] = React.useState(false);
  //const [selectedValue, setSelectedValue] = React.useState(emails[1]);



  const handleClose = () => {
    setOpen(false);

  };

  // end
  React.useEffect(() => {
    let newInfo = { ...appInfo };
    newInfo.user = user;
    newInfo.Menus = [
    ];
    newInfo.ProfileMenus = [
      {
        title: 'Profile', action: () => {
          navigate('/profile');
        }
      },
      {
        title: 'Settings', action: () => {
          navigate('/setting');
        }
      },
      {
        title: 'Change Password', action: () => {
          navigate('/change-password');
        }
      },
      {
        title: 'Training Data', action: () => {
          navigate('/training-data');
        }
      },
      {
        title: 'Document Management', action: () => {
          navigate('/documentmanagement');
        }
      },
      {
        title: 'User Management', action: () => {
          navigate('/user-management');
        }
      },
      {
        title: 'Logout', action: () => {
          localStorage.clear('auth_token', 'auth_token_type')
          toast.success("You have been logged out")
          navigate("/login");
        }
      },
      {
        title: 'About', action: () => {
          setOpen(true);

        }
      },
    ];

    if (newInfo.SideMenuSection === undefined || newInfo.SideMenuSection === '') {
      newInfo.SideMenuSection = true;
      setShowSideMenuSection(true);
    }

    newInfo.IsReady = true;

    updateAppInfo(newInfo);

  }, [user.IsAdmin, user.IsTrainingManager]);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [showSideMenu, setShowSideMenu] = React.useState(true);
  const [showSideMenuSection, setShowSideMenuSection] = React.useState(true);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
    setShowSideMenu(!showSideMenu);
    let newInfo = { ...appInfo };
    newInfo.SideMenu = showSideMenu;
    updateAppInfo(newInfo);
  };

  const handleShowSideSection = (event) => {
    setAnchorElNav(event.currentTarget);
    setShowSideMenuSection(!showSideMenuSection)
    let newInfo = { ...appInfo };
    newInfo.SideMenuSection = !showSideMenuSection;
    updateAppInfo(newInfo);
  }

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (menu) => {
    if (menu.action) {
      menu.action();
    }
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (menu) => {
    if (menu.action) {
      menu.action();
    }
    setAnchorElUser(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  function handleSelectedValueTraining(value) {
    // console.log("selected value: ", value);
  }

  return (
    <AuthProvider>

      <ThemeProvider theme={theme}>

        <CssBaseline />
        <About
          //selectedValue={selectedValue}
          open={open}
          onClose={handleClose}
        />
        <AppBar position="static">
          <Container maxWidth="xxl">
            <Toolbar disableGutters>
              <Tooltip title="Chat history">
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
              </Tooltip>


              <PrecisionManufacturingIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'flex' },
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.3rem',
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                Chat
              </Typography>

              {(window.location.pathname === '/' || window.location.pathname === '/home') && (
                <Typography
                  variant="h6"
                  noWrap
                  component="a"
                  sx={{
                    mr: 2,
                    display: { xs: 'none', md: 'flex' },
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none',
                  }}
                >
                  {/* <IconButton onClick={() => { handleClickOpen() }}
                    style={{ color: 'inherit', fontSize: '14px', marginLeft: '10px' }}>
                    <FolderZipIcon />
                    <span>SELECT TRAINING</span>
                  </IconButton> */}
                </Typography>
              )}

              <PrecisionManufacturingIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
              <Typography
                variant="h5"
                noWrap
                component="a"
                href="/"
                sx={{
                  mr: 2,
                  display: { xs: 'flex', md: 'none' },
                  flexGrow: 1,
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.3rem',
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                Chat
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {appInfo.Menus && appInfo.Menus.map((page) => (
                  <Button
                    key={page.title}
                    onClick={() => { handleCloseNavMenu(page) }}
                    sx={{ my: 2, color: 'white', display: 'block' }}
                  >
                    {page.title}
                  </Button>
                ))}
              </Box>

              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt="Admin" src="/static/images/avatar/2.jpg" />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {/* {appInfo.ProfileMenus && appInfo.ProfileMenus.map((setting) => (
                    (user.IsAdmin === true && (<MenuItem key={setting.title} onClick={() => { handleCloseUserMenu(setting) }}>
                      <Typography textAlign="center">{setting.title}</Typography>
                    </MenuItem>))

                      ((user.IsAdmin === false && (setting.title === 'Profile' || setting.title === 'Logout')) && (<MenuItem key={setting.title} onClick={() => { handleCloseUserMenu(setting) }}>
                        <Typography textAlign="center">{setting.title}</Typography>
                      </MenuItem>))
                  ))} */}

                  {appInfo.ProfileMenus &&
                    appInfo.ProfileMenus.map((setting) => (
                      <div key={setting.title}>
                        {user.IsAdmin === true && (
                          <MenuItem key={setting.title} onClick={() => { handleCloseUserMenu(setting) }}>
                            <Typography textAlign="center">{setting.title}</Typography>
                          </MenuItem>
                        )}

                        {user.IsAdmin === false && user.IsTrainingManager === false && (setting.title === 'Profile' || setting.title === 'Logout') && (
                          <MenuItem key={setting.title} onClick={() => { handleCloseUserMenu(setting) }}>
                            <Typography textAlign="center">{setting.title}</Typography>
                          </MenuItem>
                        )}

                        {user.IsTrainingManager === true && (setting.title !== 'User Management' && setting.title !== 'Settings') && (
                          <MenuItem key={setting.title} onClick={() => { handleCloseUserMenu(setting) }}>
                            <Typography textAlign="center">{setting.title}</Typography>
                          </MenuItem>
                        )}

                      </div>
                    ))
                  }

                </Menu>
              </Box>

              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="open and close note section">
                  <IconButton size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleShowSideSection}
                    color="inherit" >
                    {appInfo.SideMenuSection === true && <ExpandMoreIcon />}
                    {!appInfo.SideMenuSection && <ExpandLessIcon />}
                  </IconButton>
                </Tooltip>

              </Box>

            </Toolbar>
          </Container>
        </AppBar>
        <Box>
          {appInfo.IsReady && <RequireAuth>
            {children}
          </RequireAuth>}
        </Box>
        {/* <Box component="footer" style={{ position: 'fixed', bottom: '0', padding: '5px' }}>
          <Copyright />
        </Box> */}
        {/* <div>
          {open && <TrainingMultiFileDocument open={open} onSubmited={(value) => { setOpen(false); handleSelectedValueTraining(value) }} onClose={() => { setOpen(false); }}></TrainingMultiFileDocument>}
        </div> */}
      </ThemeProvider>
    </AuthProvider>

  );
}

export default MainLayout;
