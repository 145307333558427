import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogActions, Divider, IconButton, InputAdornment, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, TextField } from '@mui/material';
import axios from '../configs';
import { teal } from '@mui/material/colors';
import EmailIcon from '@mui/icons-material/Email';
import SubjectIcon from '@mui/icons-material/Subject';
import ShareIcon from '@mui/icons-material/Share';
import { toast } from "react-toastify";

export default function ShareEmail(props) {
    const { onClose, open, onSubmited } = props;
    // const [fromEmail, setFromEmail] = useState("");
    // const [toEmail, setToEmail] = useState("");
    // const [subject, setSubject] = useState("");
    const auth_token = localStorage.getItem("auth_token");
    const auth_token_type = localStorage.getItem("auth_token_type");
    const token = auth_token_type + " " + auth_token;

    const closeDialog = (e, reason) => {
        if (reason === 'escapeKeyDown') {
            return;
        }
        if (reason === 'backdropClick') {
            return;
        }
        onClose();
    };

    const [formSendEmail, setFormSendEmail] = useState({
        to_email: "",
        subject: props.sessionContentChat[0].chatPrompt,
    })

    const onChangeForm = (label, event) => {
        switch (label) {
            case "to_email":
                setFormSendEmail({ ...formSendEmail, to_email: event.target.value });
                break;
            case "subject":
                setFormSendEmail({ ...formSendEmail, subject: event.target.value });
                break;
            default:
                break;
        }
    };

    React.useEffect(() => {
        // setSubject('');
        setFormSendEmail({ ...formSendEmail, subject: props.sessionContentChat[0].chatPrompt });
    }, []);

    function sendMail() {
        var link = "mailto:" + formSendEmail.to_email
            + "?cc=nguyenthanhluanuit@gmail.com"
            + "&subject=" + encodeURIComponent(formSendEmail.subject)
            + "&body=" + encodeURIComponent(toText());

        window.location.href = link;
    }


    // C2. send email with api python
    const sendDataToPython = async () => {
        if (!formSendEmail.to_email || !formSendEmail.subject || !props.sessionContentChat) {
            console.error('Missing email data');
            return;
        }
        const sendData = {
            receiver: formSendEmail.to_email,
            subject: formSendEmail.subject,
            messages: props.sessionContentChat
        };

        try {
            const response = await axios.post('/email/send-chat-gpt-email', sendData, { headers: { Authorization: token } });
            // send-chat-gpt-email-using-google-api 
            // const response = await axios.post('/email/send-chat-gpt-email-using-google-api', sendData, { headers: { Authorization: token } });
            toast.success(response.data.detail);
            onClose();

        } catch (error) {
            console.error(error);
        }
    };

    // function toText() {
    //     // document.getElementById('chatlogWrapper').innerHTML = content
    //     // return document.getElementById('chatlogWrapper').textContent
    //     return (<div id="chatlogWrapper">
    //         <div class="chatLog">
    //             {props.sessionContentChat.length > 0 && props.sessionContentChat.map((chat, idx) => (
    //                 <div class="chat" key={idx}>
    //                     <div style={{ backgroundColor: 'rgb(255 247 238)', borderRadius: '15px', padding: '20px' }}>{chat.chatPrompt}</div>
    //                     <div style={{ backgroundColor: 'rgb(246 255 253)', borderRadius: '15px', padding: '20px' }}>{chat.botMessage}</div>

    //                 </div>
    //             ))}
    //         </div>
    //     </div>).textContent
    // }

    function toText() {
        var chatLogWrapper = document.createElement('div'); // create DOM element
        chatLogWrapper.innerHTML = `
        <div class="chatLog">
            ${props.sessionContentChat.length > 0 && props.sessionContentChat.map((chat, idx) => (
            `<div class="chat" key=${idx}>
                    <div style="background-color: rgb(255 247 238); border-radius: 15px; padding: 20px;">${chat.chatPrompt}</div>
                    <div style="background-color: rgb(246 255 253); border-radius: 15px; padding: 20px;">AI: ${chat.botMessage}</div>
                </div>`
        )).join('')}
        </div>`;

        return chatLogWrapper.textContent;
        // return encodeURIComponent(chatLogWrapper.outerHTML);
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={open}
            onClose={closeDialog}>
            <DialogTitle>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ color: '#0297a1' }}> <ShareIcon /> Share Email</div>
                </div>
            </DialogTitle>
            <Divider />
            <div style={{ width: '100%', padding: '10px' }}>
                <label htmlFor="to_email" className='font_text' style={{ paddingBottom: 10 }}>To</label>
                <TextField
                    autoFocus
                    margin="dense"
                    //label="To:"
                    id="to_email"
                    fullWidth
                    variant="outlined"
                    onChange={(event) => {
                        onChangeForm("to_email", event)
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton type="button" sx={{ p: '10px' }} aria-label="to_email">
                                <EmailIcon />
                            </IconButton>
                        </InputAdornment>,
                    }}
                />
                <label htmlFor="subject" className='font_text' style={{ paddingBottom: 10 }}>Subject</label>
                <TextField
                    margin="dense"
                    //label="Subject:"
                    id="subject"
                    fullWidth
                    variant="outlined"
                    value={formSendEmail.subject}
                    onChange={(event) => {
                        onChangeForm("subject", event)
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton type="button" sx={{ p: '10px' }} aria-label="subject">
                                <SubjectIcon />
                            </IconButton>
                        </InputAdornment>,
                    }}
                />

            </div>
            <DialogContent>

            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>Cancel</Button>
                <Button disabled={formSendEmail.to_email.trim() === '' || formSendEmail.subject.trim() === ''} onClick={sendDataToPython}>Send</Button>
            </DialogActions>
        </Dialog>
    );
}