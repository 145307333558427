import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Avatar, Checkbox, DialogActions, Divider, IconButton, InputAdornment, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, TextField } from '@mui/material';
import axios from '../configs';
import ArticleIcon from '@mui/icons-material/Article';
import { teal } from '@mui/material/colors';
import SearchIcon from '@mui/icons-material/Search';
import { toast } from 'react-toastify';
import UploadDocument from './UploadDocument';
import AddIcon from '@mui/icons-material/Add';
import { useAppStore } from "../utils/appstate";
import Loading from '../components/Loading';
import LoadingData from '../components/LoadingData';

export default function SectionListDialog(props) {
    // const { onClose, open, onSubmited } = props;
    const { onClose, open, onSubmited } = props;
    const auth_token = localStorage.getItem("auth_token");
    const auth_token_type = localStorage.getItem("auth_token_type");
    const token = auth_token_type + " " + auth_token;

    const closeDialog = (e, reason) => {
        if (reason === 'escapeKeyDown') {
            return;
        }
        if (reason === 'backdropClick') {
            return;
        }
        onClose();
    };

    const [sections, setSections] = React.useState([]);
    const [searchText, setSearchText] = React.useState("");
    const [selectedSection, setSelectedSection] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        setSections([]);
        setSearchText('');
        onSearch('');
    }, []);

    const handleSearchChange = (event) => {
        const searchTextValue = event.target.value;
        setSearchText(searchTextValue);
        setTimeout(() => {
            // do search logic here           
            if (searchTextValue) {
                onSearch(searchTextValue);
            } else {
                onSearch("");
            }
        }, 800);
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            onSearch(searchText);
        }
    };

    const handleSearch = (event) => {
        onSearch(searchText);
    }

    const onSearch = async (search_text) => {

        try {
            const res = await axios.get("/section/get-list-sections-for-research-note-with-search-keyword", {
                headers: { Authorization: token }, params: { key_search: search_text }
            })
                .then(response => {

                    setIsLoading(false);
                    const resTemp = response.data.result;
                    setSections(resTemp);
                });

        } catch (e) {
            console.error(e);
        }
    };
    // function uuidv4() {
    //     return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    //         (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    //     );
    // }

    // const appInfo = useAppStore((state) => state.AppInfo);
    // const updateAppInfo = useAppStore((state) => state.updateAppInfo);
    // const handleDocumentClick = (selectedDoc) => {
    //     setSelectedDocument(selectedDoc);
    //     closeDialog();

    //     //return slected training value from dialog TrainingMultiFileDocuemnt

    //     appInfo.SelectedTraining = {
    //         id: uuidv4(),
    //         training_id: selectedDoc.training_id,
    //         name: selectedDoc.name,
    //         version: selectedDoc.version,
    //         vector_file_path: selectedDoc.vector_file_path
    //     }

    //     updateAppInfo({ ...appInfo });
    //     //------------

    //     onSubmited(selectedDoc);
    //     // selectedValueTraining(selectedDoc);

    // };

    const handleSectionClick = (selectedSection) => {
        setSelectedSection(selectedSection);
        onSubmited(selectedSection);
        closeDialog();
    }

    // onSubmited(selectedSection);

    return (

        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={open}
            onClose={closeDialog}>
            <DialogTitle>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>Choose available section</div>

                </div>
            </DialogTitle>
            <Divider />
            <div style={{ width: '100%', padding: '10px' }}>
                <TextField
                    label="Search"
                    variant="outlined"
                    value={searchText}
                    onChange={handleSearchChange}
                    onKeyPress={handleKeyPress}
                    style={{ width: '100%' }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleSearch}>
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>,
                    }}
                />
            </div>
            <DialogContent>
                <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {isLoading ? (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <LoadingData />
                        </div>
                    ) : (sections.length > 0 ? sections.map((sec) => {
                        return (
                            <div key={sec.section_id}>
                                <ListItem>
                                    <ListItemButton onClick={() => handleSectionClick(sec)}>
                                        {/* <ListItemButton> */}
                                        <ListItemAvatar>
                                            <Avatar sx={{ bgcolor: teal[100], color: teal[600] }}>
                                                <ArticleIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText id={sec.section_id} primary={sec.name} secondary={`Created date: ${sec.created_at}`} />
                                    </ListItemButton>
                                </ListItem>
                                <Divider light />
                            </div>
                        );
                    }) : <h5>No data to display</h5>)
                    }
                </List>
                {/* ------ end list check box -------*/}
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>Cancel</Button>
                {/* <Button onClick={handleSaveChangeAdd}>Save Change</Button> */}
            </DialogActions>
        </Dialog>
    );
}