import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogActions } from '@mui/material';
import Utils from '../utils/utils';

export default function ViewPdfDocument(props) {
    const { onClose, open } = props;
    const auth_token = localStorage.getItem("auth_token");
    // const [pageNumber, setPageNumber] = React.useState(1);
    // React.useEffect(() => {
    //     setPageNumber(props.page);
    // }, [props.page])
    const closeDialog = (e, reason) => {
        if (reason === 'escapeKeyDown') {
            return;
        }
        if (reason === 'backdropClick') {
            return;
        }
        onClose();
    };

    // const idFile = props.id ? props.source : props.filename;

    return (

        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            open={open}
            onClose={closeDialog}
        >
            <DialogTitle id="responsive-dialog-view-pdf">
                {"View Document"}
            </DialogTitle>
            <DialogContent>
                {props.id && (
                    <div style={{ width: '100%', height: '650px' }}>
                        {props.filename.endsWith('.pdf') || props.filename.endsWith('.txt') ? (
                            <embed
                                id="ifameviewPdf"
                                type="application/pdf"
                                title={props.filename}
                                // src={`${Utils.ApiEndpoint}/documents/file/${props.id}?token=${auth_token}&page=${props.page}#toolbar=1&page=${props.page}`}
                                // src={`${Utils.ApiEndpoint}/documents/file/${idFile}?token=${auth_token}#toolbar=1&page=${props.page}`}
                                src={`${Utils.ApiEndpoint}/documents/file/${props.id}?token=${auth_token}#toolbar=1&page=${props.page}`}
                                width="100%"
                                height="95%"
                            />
                        ) : (
                            <iframe
                                id="ifameviewOffice"
                                title='View Document'
                                src={`https://view.officeapps.live.com/op/embed.aspx?src=${Utils.ApiEndpoint}/documents/file/${props.id}?token=${auth_token}#toolbar=1`}
                                width="100%"
                                height="100%"
                            ></iframe>
                        )}
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={closeDialog}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}