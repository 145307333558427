var ChatUtils = {
    storeTraining: (chanId) => {
        localStorage.setItem("current_training_id", chanId);
    },
    storeTemperature: (temperature) => {
        localStorage.setItem("current_temperature", temperature);
    },
    storeSelectedChatType: (chatType) => {
        localStorage.setItem("current_chat_type", chatType);
    },
    storeSession: (session_id) => {
        localStorage.setItem("current_session_id", session_id);
    },
    getSelectedChatType: () => {
        let id = localStorage.getItem("current_chat_type");
        if (!id) {
            return 'conversation';
        }
        return id;
    },
    getStoreTemperature: () => {
        let id = localStorage.getItem("current_temperature");
        if (!id) {
            return 0;
        }
        return id;
    },
    getStoreTraining: () => {
        let id = localStorage.getItem("current_training_id");
        if (!id) {
            return "";
        }
        return id;
    },
    storeDocumentId: (docId) => {
        localStorage.setItem("current_document_id", docId);
    },
    getStoreDocumentId: () => {
        let id = localStorage.getItem("current_document_id");
        if (!id) {
            return "";
        }
        return id;
    },
    getStoreSession: () => {
        let id = localStorage.getItem("current_session_id");
        if (!id) {
            return "";
        }
        return id;
    },
    uuidv4: () => {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    },
    getToken: () => {
        const auth_token = localStorage.getItem("auth_token");
        const auth_token_type = localStorage.getItem("auth_token_type");
        const token = auth_token_type + " " + auth_token;
        return token;
    },
    truncateFilename: (filename, maxLength) => {
        if (!filename) {
            return '';
        }
        return filename.length > maxLength
            ? filename.slice(0, maxLength) + '...'
            : filename;
    },
    MAX_FILENAME_LENGTH: 40,
    Logs: [],
    CurrentLog: {}

}

export default ChatUtils;