var Utils = {
    //ApiEndpoint: "http://localhost:4544",
    ApiEndpoint: "https://ai.phamiliartech.com/api",
    //SocketEndpoint: "ws://localhost:4544",
    SocketEndpoint: "wss://ai.phamiliartech.com/api",
    isNumeric: (value) => {
        return /^-?\d+$/.test(value);
    },
    formatMoney: (value) => {
        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0
        });

        let valueData = 0;
        if (typeof value === 'string' || value instanceof String) {
            valueData = parseFloat(value);
        } else {
            valueData = value;
        }

        return formatter.format(valueData);
    },
    formatDateTime: (value) => {
        let valueData = 0;
        if (!value) {
            return 'N/A';
        }
        if (typeof value === 'string' || value instanceof String) {
            valueData = new Date(value);
        } else {
            valueData = value;
        }
        return new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(valueData);

    },
    formatDate: (value) => {
        let valueData = 0;
        if (!value) {
            return 'N/A';
        }
        if (typeof value === 'string' || value instanceof String) {
            valueData = new Date(value);
        } else {
            valueData = value;
        }
        return new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(valueData);

    },
    parseJwt: (token) => {
        if (!token) {
            return { IsAdmin: false, IsTrainingManager: false, IsLogin: false };
        }
        try {
            let base64Url = token.split('.')[1];
            let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            let user = JSON.parse(jsonPayload);
            user.IsLogin = true;
            //debugger
            if (user.roles) {
                let isAdmin = user.roles.indexOf('admin') !== -1;
                let isTrainingManager = user.roles.indexOf('training manager') !== -1;
                if (isAdmin) {
                    user.IsAdmin = true;
                } else {
                    user.IsAdmin = false;
                }
                if (isTrainingManager) {
                    user.IsTrainingManager = true;
                } else {
                    user.IsTrainingManager = false;
                }
            } else {
                user.IsAdmin = false;
                user.IsTrainingManager = false;
            }

            return user;

        } catch (error) {
            return { IsAdmin: false, IsTrainingManager: false, IsLogin: false }
        }
    }
}

export default Utils;