import axios from "axios"
import Utils from "./utils/utils";

const instance = axios.create({
  baseURL: `${Utils.ApiEndpoint}`
})

instance.interceptors.response.use(
    response => response,
    error => {  
        if (error.response && 403 === error.response.status) {
            console.log('Got 403 response.')
            localStorage.clear();
            console.log('Local storage cleared');
            window.location.replace("/login");
          } else {
            return Promise.reject(error)
          }
    });


export default instance