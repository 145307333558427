import { useState } from 'react'
import { useNavigate } from "react-router-dom";
import React from 'react'
import { toast } from 'react-toastify';
import axios from '../configs'
import './register.css';
import Button from '@mui/material/Button';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import UploadDocument from './UploadDocument'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PreviewIcon from '@mui/icons-material/Preview';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ViewPdfDocument from '../components/ViewDocument';
import Utils from './../utils/utils';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import VisibilityIcon from '@mui/icons-material/Visibility';

const DocumentManagement = () => {
  const navigate = useNavigate();
  const auth_token = localStorage.getItem("auth_token");
  const auth_token_type = localStorage.getItem("auth_token_type");
  const token = auth_token_type + " " + auth_token;
  const [open, setOpen] = React.useState(false);
  const [openViewPdf, setOpenViewPdf] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState();
  const [dataResult, setdataResult] = useState([]);

  const columns = [
    {
      field: 'documentname',
      headerName: 'Document Name',
      width: 200,
      editable: true,
    },
    {
      field: 'filename',
      headerName: 'File Name',
      width: 250,
      editable: true,
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 100,
      editable: true,
    },
    {
      field: 'name',
      headerName: 'Created User',
      width: 150,
      editable: true,
    },
    {
      field: 'modified_at',
      headerName: 'Modified Date',
      width: 180,
      editable: true,
      valueFormatter: (params) => {
        if (params.value === 'N/A') {
          return params.value;
        }
        return Utils.formatDateTime(params.value);
      },
    },
    {
      field: 'Actions',
      headerName: 'Actions', width: 150,
      renderCell: (params) => (
        <>
          <Tooltip title="View document">
            <IconButton
              aria-label="view document" size="large"
              onClick={(event) => {
                setSelectedValue(params.row);
                setOpenViewPdf(true);
              }}
              style={{ padding: 10 }}
            >
              <VisibilityIcon style={{ color: 'teal' }} />

            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              aria-label="delete" size="large"
              // onClick={(event) => {
              //   onSubmitDelete((params.row),event );
              // }}
              onClick={() => handleClickOpenDelete(params.row)}
              style={{ padding: 10 }}
            >
              <DeleteOutlineOutlinedIcon style={{ color: 'red' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download">
            <IconButton
              aria-label="download" size="large"
              onClick={(event) => {

                downloadfile((params.row), event);
              }}
              style={{ padding: 10 }}
            >
              <FileDownloadOutlinedIcon style={{ color: 'teal' }} />

            </IconButton>
          </Tooltip>

         

        </>
      )
    },

  ];

  // open dialog delete
  const handleClickOpenDelete = (data) => {
    setSelectedValue(data);
    setOpenDelete(true);
  };

  /// open dialog updata
  const handleClickOpen = () => {
    setOpen(true);
  };

  const getDocuments = async (event) => {

    try {
      let response = await axios
        .get("/documents/getfile", {
          headers: { Authorization: token },
        });
        
      setdataResult(response.data.result);  
      console.log("info Doc",response.data.result )
    } catch (error) {

    }
    //fetch data from get user api

  };

  React.useEffect(() => {
    if (token) {
      getDocuments();
    }
  }, [])

  //i close delete dialog
  const handleCloseDelete = () => {
    setOpenDelete(false);
    setSelectedValue();
  };

  // close update dialog
  const handleClose = () => {
    setOpen(false);
    setSelectedValue();
  };

 
  // download
  const downloadfile = async (data, event) => {
    debugger
    let document_id = data.id;
    axios({
      // url: `/documents/downloadDocument/?id=${document_id}`, 
      url: `/documents/downloadDocument/${document_id}`,
      method: 'GET',
      responseType: 'blob', // important
      headers: { Authorization: token }
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', data.filename); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });

  }


  //   Submit handler delete document
  const onSubmitDelete = async (row) => {
    const data = {
      id: row.id,
      filename: row.filename,
      path: row.path,
      type: row.type,
      name: row.name,
      documentname: row.documentname,
      vector_file_path: ""
    }

    await axios
      .post(`/documents/deleteDocument`, data, {
        headers: { Authorization: token },
      })
      .then((response) => {
        getDocuments();
        toast.success(response.data.detail);
        handleCloseDelete();
      })
      .catch((error) => {
        // add error notif        
        //toast.error(error.message);
        toast.error(error.response.data.detail.message)
        handleCloseDelete();
      });
  };


  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container style={{ maxWidth: '100%', margin: '20px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 0 10px 0' }}>
            <div className='csstext'>
              Document Management
            </div>
            <div style={{ paddingTop: '10px', marginRight: '50px' }}>
              <Button onClick={handleClickOpen} variant="outlined" startIcon={<FileUploadOutlinedIcon style={{ fontSize: 'medium' }} />} >
                <span style={{ color: 'teal', textAlign: 'center' }}>
                  Upload
                </span>
              </Button>
              <UploadDocument
                selectedValue={selectedValue}
                open={open}
                onClose={handleClose}
                onSubmit={() => { getDocuments(); }}
              />
            </div>
          </div>

          {openViewPdf && <ViewPdfDocument open={openViewPdf} filename={selectedValue.filename} id={selectedValue.id} onClose={() => { setOpenViewPdf(false); }}></ViewPdfDocument>}
          <div style={{ height: '100%', width: '100%' }}>
            {dataResult.length > 0 &&
              // <DataGrid
              //   rows={dataResult}
              //   columns={columns}
              //   getRowId={(row) => row.id}
              //   pageSize={8}
              //   autoHeight />
              <Box sx={{ height: '60vh', width: '100%' }}>
                <DataGrid
                  rows={dataResult}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  checkboxSelection
                  disableRowSelectionOnClick
                />
              </Box>
            }
            {dataResult.length === 0 &&
              <div>
                <p> No data</p>
              </div>}
          </div>

          <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={openDelete}
            onClose={handleCloseDelete}
            value={selectedValue}
          >
            <DialogTitle id="responsive-dialog-title">
              {"comfirm information"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleCloseDelete}>
                Close
              </Button>
              <Button onClick={() => { onSubmitDelete(selectedValue) }} autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>

        </Container>
      </React.Fragment>
    </>

  )
}

export default DocumentManagement
