import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogActions } from '@mui/material';
import Utils from '../utils/utils';
import axios from './../configs'
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import { toast } from "react-toastify";
import { useAppStore } from "../utils/appstate";

export default function AddChildSectionDialog(props) {

    console.log("add child props: ", props);
    const { onClose, open } = props;

    const auth_token = localStorage.getItem("auth_token");
    const auth_token_type = localStorage.getItem("auth_token_type");
    const token = auth_token_type + " " + auth_token;
    const updateAppInfo = useAppStore((state) => state.updateAppInfo);
    const appInfo = useAppStore((state) => state.AppInfo);

    const closeDialog = (e, reason) => {
        if (reason === 'escapeKeyDown') {
            return;
        }
        if (reason === 'backdropClick') {
            return;
        }
        onClose();
    };


    const [formAddSection, setFormAddSection] = React.useState({
        section_id: "",
        parent_id: "",
        user_id: "",
        name: "",
        content: "",
        reference: "",
        type: "",
        training_id: "",
        document_id: "",
        created_user: "",
        created_at: "",
        modified_at: "",
        order_id: 0
    })
    let orderId = 0;
    const onChangeForm = (label, event) => {
        console.log("Huong log props: ", props)
        // if (appInfo.rootSection.children.length > 0) {
        //     const lastItem = appInfo.rootSection.children[appInfo.rootSection.children.length - 1];
        //     console.log("lastItem: ", lastItem)
        //     orderId = lastItem.order + 1;
        // }
        if (props.section.children && props.section.children.length > 0) {
            const lastItem = props.section.children[props.section.children.length - 1];
            if (!lastItem.order_id) {
                lastItem.order_id = 0;
            }
            orderId = lastItem.order_id + 1;
        } else {
            orderId = orderId + 1;
        }


        switch (label) {
            case "name":
                setFormAddSection({ ...formAddSection, name: event.target.value, parent_id: props.section.section_id, order_id: orderId });
                break;
            // case "version":
            //   setFormAddTraining({ ...formAddTraining, version: event.target.value });
            //   break;
            default:
                break;
        }
    };

    const addNewNodeToTree = (tree, targetNode, newNode) => {
        //debugger
        if (tree.section_id === targetNode.section_id) {
            if (!tree.children || tree.children === 'undefined') {
                tree.children = [];
            }
            tree.children.push(newNode);
            return true;
        }

        let result = false;
        if (tree.children && tree.children.length > 0) {
            tree.children.forEach((child) => {
                if (result) {
                    return true;
                }
                let found = addNewNodeToTree(child, targetNode, newNode);
                if (found) {
                    result = true;
                }
            });
        }

        return result;
    }

    const handleSaveChange = async (event) => {
        debugger
        event.preventDefault();
        await axios
            .post("/section/add-child-section-note-with-parentId", formAddSection, { headers: { Authorization: token } })
            .then((response) => {
                debugger
                let appInfoData = { ...appInfo }
                console.log(response.data)
                addNewNodeToTree(appInfoData.rootSection, props.section, response.data.result);
                updateAppInfo(appInfoData);
                //    props.section.children.push(response.data.detail)

                closeDialog();

                toast.success(response.data.detail);
                // history('/training-data-detail?id=' + response.data.result.training_id);
            })
            .catch((error) => {
                // add error notif
                toast.error(error.response.data.detail);

            });
    };

    return (
        <>
            {/*----ui dialog-add section------*/}
            <div>
                <Dialog
                    open={open}
                    onClose={closeDialog}
                    style={{ minWidth: '60%' }}>
                    <DialogTitle>Add Note</DialogTitle>
                    <Divider />

                    <DialogContent>
                        <DialogContentText>
                        </DialogContentText>
                        <label htmlFor="sectionname" className='font_text' style={{ paddingBottom: 10 }}>Note Name</label>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            type="text"
                            fullWidth

                            onChange={(event) => {
                                onChangeForm("name", event)
                            }}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={closeDialog}>Cancel</Button>
                        <Button disabled={formAddSection.name.trim() === ''} onClick={handleSaveChange}>Save</Button>
                    </DialogActions>
                </Dialog>
            </div>
            {/*-----end ui dialog-add section-----*/}
        </>
    );
}