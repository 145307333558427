import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogActions, DialogContentText, Divider, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import axios from '../configs';

export default function TrainingEdit(props) {
    const { onClose, open, onSubmited } = props;
    const auth_token = localStorage.getItem("auth_token");
    const auth_token_type = localStorage.getItem("auth_token_type");
    const token = auth_token_type + " " + auth_token;


    const closeDialog = (e, reason) => {
        if (reason === 'escapeKeyDown') {
            return;
        }
        if (reason === 'backdropClick') {
            return;
        }
        onClose();
    };

    React.useEffect(() => {
        const dataFormEdit = {
            training_id: props.trainingData.training_id,
            name: props.trainingData.name,
            version: props.trainingData.version,
            status: props.trainingData.status,
            vector_file_path: "",
            json_file_path: "",
            created_user: props.trainingData.created_user,
            modified_user: props.trainingData.modified_user,
            modified_at: props.trainingData.modified_at,
            created_at: props.trainingData.created_at,
            recommendation_questions: "",
            summary_content: ""
        }
        // setFormEditTraining(props.trainingData);
        setFormEditTraining(dataFormEdit);
    }, [props.trainingData]);

    const [formEditTraining, setFormEditTraining] = React.useState({})


    const onChangeFormEditTraining = (label, event) => {
        switch (label) {
            case "name":
                setFormEditTraining({ ...formEditTraining, name: event.target.value });
                break;
            case "version":
                setFormEditTraining({ ...formEditTraining, version: event.target.value });
                break;
            default:
                break;
        }
    };

    const handleSaveChangeEditTraining = async (event) => {
        debugger
        event.preventDefault();
        await axios
            .post("/training/edit-training-data", formEditTraining, { headers: { Authorization: token } })
            .then((response) => {
                onSubmited(response);
                toast.success(response.data.detail);
            })
            .catch((error) => {
                toast.error(error.response.data.detail);

            });
    };


    return (

        <Dialog maxWidth={"sm"} open={open} onClose={closeDialog}>
            <DialogTitle>Edit Training Data</DialogTitle>
            <Divider />

            <DialogContent>
                <DialogContentText>
                </DialogContentText>
                {/* <div>Name:</div> */}
                <label htmlFor="name" className='font_text' style={{ paddingBottom: 10 }}>Name</label>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    value={formEditTraining.name}
                    //label="Name"
                    type="text"
                    fullWidth
                    // variant="outlined"
                    variant="outlined"
                    onChange={(event) => {
                        onChangeFormEditTraining("name", event)
                    }}
                />
                {/* <div>Version:</div> */}
                <label htmlFor="version" className='font_text' style={{ paddingBottom: 10 }}>Version</label>
                <TextField
                    autoFocus
                    margin="dense"
                    id="version"
                    value={formEditTraining.version}
                    //label="Training Version"
                    type="text"
                    fullWidth
                    variant="outlined"
                    //variant="standard"
                    onChange={(event) => {
                        onChangeFormEditTraining("version", event)
                    }}
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>Cancel</Button>
                <Button onClick={handleSaveChangeEditTraining}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}