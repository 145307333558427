import React from 'react'
import { useNavigate } from "react-router-dom";
import Divider from '@mui/material/Divider';

export default function AnswerReferenceComponent(props) {
    const navigate = useNavigate();
    const {chat, handleClickOpenDetailReference} = props;

    
    function removeDuplicates(arr, equals) {
        var originalArr = arr.slice(0);
        var i, len, val;
        arr.length = 0;

        for (i = 0, len = originalArr.length; i < len; ++i) {
            val = originalArr[i];
            if (!arr.some(function (item) { return equals(item, val); })) {
                arr.push(val);
            }
        }
        return arr;
    }

    function thingsEqual(thing1, thing2) {
        return thing1.metadata.documentname === thing2.metadata.documentname
            && thing1.metadata.page === thing2.metadata.page;
    }


    return (
        <>
            {chat.reference && chat.reference.length > 0 && <Divider variant="middle" style={{ paddingTop: '10px' }} />}
            {chat.reference && chat.reference.length > 0 && <div style={{ fontSize: '12px', fontStyle: 'italic' }}>
                <p >References:</p>
                <div style={{ marginTop: '-10px', display: 'flex', flexFlow: 'wrap' }}>
                    {removeDuplicates(chat.reference, thingsEqual).map((item, idx) => (
                      
                       <div key={idx}>
                            {item.page_content.length >= 500 &&
                                <div style={{ backgroundColor: 'rgb(249, 249, 249)', padding: '5px', marginRight: '10px', marginBottom: '10px' }} key={idx}>
                                    <span onClick={() => { handleClickOpenDetailReference(item) }} style={{ cursor: 'pointer', borderRadius: '15px' }}>
                                        <span> {item.metadata.documentname}-Page: {item.metadata.page} </span>
                                    </span>
                            </div>
                            }
                        </div>
                       

                        // <div style={{ backgroundColor: 'rgb(249, 249, 249)', padding: '5px', marginRight: '10px', marginBottom: '10px' }} key={idx}>
                        //     <span onClick={() => { handleClickOpenDetailReference(item) }} style={{ cursor: 'pointer', borderRadius: '15px' }}>
                        //         <span> {item.metadata.documentname}-Page: {item.metadata.page} </span>
                        //     </span>
                        // </div>
                    ))
                    }
                </div>

            </div>
            }
        </>
    )
}
