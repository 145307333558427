import React from 'react'
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import ShareIcon from '@mui/icons-material/Share';
import ShareEmail from '../components/ShareEmail';
import TrainingFileDocument from './TrainingFileDocument';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { Tooltip } from '@mui/material';
import Utils from '../utils/utils';
import DetailReference from './DetailReference'
import Spinner from '../components/Spinner';
import DocumentsOfTraining from './DocumentsOfTraining';
import ViewPdfDocument from '../components/ViewDocument';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import ChatUtils from '../utils/chat_utils';
import AnswerChatComponent from '../components/AnswerChatComponent';
import axios from './../configs'
import { toast } from 'react-toastify';

export default function Chat(props) {

    const [inputPrompt, setInputPrompt] = useState("");
    const [chatLog, setChatLog] = useState([]);
    const [currentChat, setCurrentChat] = useState({});
    const [err, setErr] = useState(false);
    const [sessionId, setSessionId] = useState('');

    // const [sessionChat, setSessionChat] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [openReference, setOpenReference] = React.useState(false);
    const [socketClosed, setSocketClosed] = React.useState(false);
    const [connecting, setConnecting] = React.useState(false);
    const [connectingSocket, setConnectingSocket] = React.useState(false);
    const ws = React.useRef();
    const [documentType, setDocumentType] = useState('');
    const [selectedDocument, setSelectedDocument] = useState({});
    const [selectedTrainingValue, setSelectedTrainingValue] = useState({});
    const [trainingId, setTrainingId] = useState('');
    const [documentId, setDocumentId] = useState('');
    const auth_token = localStorage.getItem("auth_token");
    const [detailReference, setDetailReference] = useState()
    const timeoutReconnect = React.useRef(null)
    const [openViewPdf, setOpenViewPdf] = React.useState(false);
    const [selectedChatTypeValue, setSelectedChatTypeValue] = useState('conversation');

    const handleClickOpen = (type) => {
        setDocumentType(type);
        setOpen(true);
    };
    const handleClickOpenDetailReference = (data) => {
        setDetailReference(data)
        setOpenReference(true);
    };

    const handleCloseDetailReference = () => {
        setOpenReference(false);

    };

    function scrollChat() {
        setTimeout(() => {
            let objDiv = document.getElementById("chatlogWrapper");
            if (objDiv && objDiv.scrollHeight)
                objDiv.scrollTop = objDiv.scrollHeight;
        }, 350);
    }


    function storeTraining(chanId) {
        ChatUtils.storeTraining(chanId);
        setTrainingId(chanId);
    }


    function storeSelectedChatType(chatType) {
        ChatUtils.storeSelectedChatType(chatType);
        setSelectedChatTypeValue(chatType);
    }

    function storeSession(session_id) {
        ChatUtils.storeSession(session_id);
        setSessionId(session_id);
    }

    function storeDocumentId(docId) {
        ChatUtils.storeDocumentId(docId);
        setDocumentId(docId);
    }

    // get list approved training 
    React.useEffect(() => {

        if (props.trainingId) {
            storeTraining(props.trainingId);

            let sessId = ChatUtils.uuidv4();
            storeSession(sessId);
        }

        let trainingId = ChatUtils.getStoreTraining();
        if (trainingId) {
            setTrainingId(trainingId);
        }


        if (props.selectedTraining) {
            setSelectedTrainingValue(props.selectedTraining);
        }

        if (props.chatLog && props.chatLog.length > 0) {
            ChatUtils.Logs = props.chatLog;
            setChatLog([
                ...ChatUtils.Logs
            ]);
        } else {
            getSummaryInfo();
        }

        let getChatType = ChatUtils.getSelectedChatType();
        if (getChatType) {
            setSelectedChatTypeValue(getChatType);
        }


        connectWithTimeout();


        return () => {
            closeChat();
        }
    }, [props.sessionId]);


    const closeChat = () => {
        if (!ws.current || !ws.current.current) return;
        setConnecting(true);
        ws.current.current.close();
    }


    const connectChat = () => {
        let training_id = ChatUtils.getStoreTraining();
        let document_id = ChatUtils.getStoreDocumentId();
        let temperature = ChatUtils.getStoreTemperature();
        let chatType = ChatUtils.getSelectedChatType()
        //let ssId = ''
        let ssId = ChatUtils.getStoreSession();

        let trainId = training_id ? training_id : trainingId;

        if (!temperature) {
            temperature = 0.0;
        }
        setConnectingSocket(true);

        let endpoint = `${Utils.SocketEndpoint}/chats/chat?session_id=${ssId}&token=${auth_token}&document_id=${document_id}&training_id=${trainId}&temperatureValue=${temperature.toString()}&chatType=${chatType}`;
        ws.current = new WebSocket(endpoint);
        setSocketClosed(false);
        let message = "";
        let reference = [];
        let lastmsg = '';
        ws.current.onopen = () => {
            setConnecting(false);
            setTimeout(() => {
                setConnectingSocket(false);
            }, 300);
        }
        ws.current.onmessage = (event) => {
            var data = JSON.parse(event.data);
            if (data.sender === 'you') {
                lastmsg = data.message;
                return;
            }
            if (data.sender === "bot") {
                if (data.type === "start") {
                    message = "";
                    let currChat = { ...ChatUtils.CurrentLog };
                    currChat.botMessage = message;
                    setCurrentChat(currChat);
                    scrollChat();
                    setErr(false);
                    setConnecting(true);
                } else if (data.type === "stream") {
                    if (data.message === "\n") {
                        message += "<br/>";
                    } else {
                        message += data.message;
                        reference = data.reference;
                    }
                    setTimeout(function () {
                        let currChat = { ...ChatUtils.CurrentLog };
                        currChat.botMessage = message;
                        setCurrentChat(currChat);
                    }, 1000);
                    scrollChat();

                    setConnecting(true);

                } else if (data.type === "info") {
                    setTimeout(function () {
                        let currChat = { ...ChatUtils.CurrentLog };
                        currChat.botMessage = data.message;
                        setCurrentChat(currChat);
                    }, 1000);
                    scrollChat();
                    setConnecting(false);
                } else if (data.type === "end") {
                    reference = data.reference;


                    setCurrentChat({
                        chatPrompt: "",
                        botMessage: ""
                    });
                    ChatUtils.Logs.push({
                        chatPrompt: lastmsg,
                        botMessage: message,
                        reference: reference,
                    });
                    setChatLog([
                        ...ChatUtils.Logs
                    ]);
                    scrollChat();
                    setConnecting(false);

                } else if (data.type === "error") {
                    ChatUtils.Logs.push({
                        chatPrompt: lastmsg,
                        botMessage: data.message,
                    });
                    setChatLog([
                        ...ChatUtils.Logs
                    ]);
                    setCurrentChat({});
                    scrollChat();
                    setErr(data.message);
                    setConnecting(false);
                }

            } else {
                // message += data.message;
                setTimeout(function () {
                    let currChat = { ...ChatUtils.CurrentLog };
                    currChat.botMessage = data.message;
                    setCurrentChat(currChat);
                }, 150);
                scrollChat();
                setConnecting(false);
            }
        }

        ws.onclose = function () {
            if (timeoutReconnect.current) clearTimeout(timeoutReconnect.current);

            timeoutReconnect.current = setTimeout(() => {
                reconnectChat();
            }, 650);
            console.log('close socket')
        };
    }

    function reconnectChat() {
        connectChat();
    }

    function connectWithTimeout() {
        closeChat();
        connectChat();
    }

    function handleSubmit(event) {
        event.preventDefault();
        sendDefaultQuestion(inputPrompt)
    }
    function sendDefaultQuestion(inputPrompt) {

        if (inputPrompt === "") {
            return;
        }

        ChatUtils.CurrentLog = {
            chatPrompt: inputPrompt,
            botMessage: "..."
        };

        setCurrentChat(ChatUtils.CurrentLog);

        ChatUtils.Logs = chatLog;


        setErr(false);

        if (ws.current.readyState !== WebSocket.CLOSED && ws.current.readyState !== WebSocket.CLOSING) {
            ws.current.send(inputPrompt);
        } else {
            setSocketClosed(true);
        }

        setInputPrompt("");
        scrollChat();
    }

    const suggestQuestion = (question) => {
        sendDefaultQuestion(question);
    }

    function handleSelectedValue(value) {
        setSelectedDocument(value);
        storeDocumentId(value.id);

        let sessId = ChatUtils.uuidv4();
        storeSession(sessId);
        ChatUtils.Logs = [];
        setChatLog([
            ...ChatUtils.Logs
        ]);

        connectWithTimeout();
        getSummaryInfo();
    }

    function handleSelectedValueTraining(value) {

        setSelectedTrainingValue(value);
        storeTraining(value.training_id);
        let sessId = ChatUtils.uuidv4();
        ChatUtils.Logs = [];
        setChatLog([
            ...ChatUtils.Logs
        ]);

        storeSession(sessId);
        setSelectedDocument({});
        storeDocumentId('');
        connectWithTimeout();
        props.getHistoryChat(value.training_id);
        getSummaryInfo();
    }

    if (socketClosed) {
        return <div>
            <div>Socket Closed</div>
            <div></div>
        </div>
    }

    const getSummaryInfo = async () => {
        const token = ChatUtils.getToken();
        let training_id = ChatUtils.getStoreTraining();
        let document_id = ChatUtils.getStoreDocumentId();
        let listData = {
            document_id: document_id,
            training_id: training_id
        }
        let _document = {}

        await axios
            .post("/documents/add-data-for-document", listData, {
                headers: { Authorization: token },

            })
            .then((response) => {
                _document = response.data.result[0];
                ChatUtils.Logs.push({
                    chatPrompt: "",
                    botMessage: _document.summary_content,
                    reference: [],
                    questtions: _document.recommendation_questions
                });
                setChatLog([
                    ...ChatUtils.Logs
                ]);
            })
            .catch((error) => {
                toast.error(error.response.data);

            });
    }


    const handleDocumentClick = (selectedDoc) => {
        setSelectedDocument(selectedDoc);
        setOpenViewPdf(true);
    };

    function showQuestions(data) {
        let result = [];
        const questions = data.split("\n");

        console.log("questions", questions);

        result = questions.map(item => item.replace(/\d+[.,)]/, ""));
        console.log("result", result);
        return result;
    }

    const MAX_FILENAME_LENGTH = ChatUtils.MAX_FILENAME_LENGTH;
    const filenameDocToDisplay = selectedDocument ? ChatUtils.truncateFilename(selectedDocument.filename, MAX_FILENAME_LENGTH) : '';
    const filenameTrainingToDisplay = selectedTrainingValue ? ChatUtils.truncateFilename(selectedTrainingValue.name, MAX_FILENAME_LENGTH) : '';
    const handleChatTypeChange = (event) => {
        storeSelectedChatType(event.target.value);
        connectWithTimeout();

    };

    return (
        <div className="App">
            {connectingSocket && <Spinner></Spinner>}
            {openReference && <DetailReference
                selectedValue={detailReference}
                open={openReference}
                onClose={handleCloseDetailReference}
            />}

            <section className="chatBox">
                <div style={{ display: 'flex', flexFlow: 'column', background: '#f9f9f9', borderRadius: '20px', padding: '10px' }}>
                    {!props.trainingId && <div style={{ display: 'flex' }}>
                        <div style={{ paddingTop: '10px' }}> Current context:</div>
                        <div style={{ paddingTop: '10px' }}>
                            {!trainingId &&
                                <span> No Context Available</span>
                            }
                            {selectedDocument && selectedDocument.id != null ?
                                (<span onClick={() => {
                                    if (selectedChatTypeValue !== 'chat_no_context') {
                                        handleDocumentClick(selectedDocument)
                                    }
                                }} style={{
                                    fontSize: '1.2em',
                                    fontWeight: 'bold',
                                    marginLeft: '5px',
                                    color: selectedChatTypeValue !== 'chat_no_context' ? '#1E90FF' : '#A9A9A9',
                                    cursor: selectedChatTypeValue !== 'chat_no_context' ? 'pointer' : 'default',
                                }}>{filenameDocToDisplay}</span>
                                ) : selectedTrainingValue && selectedTrainingValue.training_id ?
                                    (<a onClick={() => {
                                        if (selectedChatTypeValue !== 'chat_no_context') {
                                            handleClickOpen('training')
                                        }
                                    }}
                                        style={{
                                            fontSize: '1.2em',
                                            fontWeight: 'bold',
                                            marginLeft: '5px',
                                            color: selectedChatTypeValue !== 'chat_no_context' ? '#1E90FF' : '#A9A9A9',
                                            cursor: selectedChatTypeValue !== 'chat_no_context' ? 'pointer' : 'default',
                                        }}>{filenameTrainingToDisplay} - {selectedTrainingValue.version}</a>
                                    ) : null}
                        </div>
                        <div>
                            <Tooltip title="Select context">
                                <div>
                                    <IconButton onClick={() => {
                                        if (selectedChatTypeValue !== 'chat_no_context') {
                                            handleClickOpen('document')
                                        }
                                    }}
                                        style={{
                                            marginLeft: '10px',
                                            color: selectedChatTypeValue !== 'chat_no_context' ? '#0297a1' : '#A9A9A9',
                                            cursor: selectedChatTypeValue !== 'chat_no_context' ? 'pointer' : 'default',
                                        }}
                                        disabled={selectedChatTypeValue === 'chat_no_context'}
                                    >
                                        <FileOpenIcon style={{ fontSize: '1.2em' }} />
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </div>
                    </div>}

                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            defaultValue="chat_no_context"
                            value={selectedChatTypeValue}
                            onChange={handleChatTypeChange}
                            disabled={connecting}
                        >
                            <FormControlLabel disabled={connecting || !trainingId} value="chat" control={<Radio />} label="Chat" />
                            <FormControlLabel disabled={connecting || !trainingId} value="conversation" control={<Radio />} label="Conversation" />
                            <FormControlLabel disabled={connecting || !trainingId} value="doc_retriever" control={<Radio />} label="Doc Retriever" />
                            <FormControlLabel disabled={connecting || !trainingId} value="doc_analysis" control={<Radio />} label="Doc Analysis" />
                            <FormControlLabel disabled={connecting} value="chat_no_context" control={<Radio />} label="Chat with LLM" />
                        </RadioGroup>
                    </FormControl>
                </div>


                <div id="chatlogWrapper" className="chatLogWrapper">
                    {!currentChat.chatPrompt && chatLog.length === 0 && <div style={{ marginTop: '20px' }}>
                        {selectedTrainingValue != null
                            && selectedTrainingValue.recommendation_questions != null && selectedTrainingValue.summary_content != null ?
                            (
                                <div>
                                    <div>
                                        <h4 style={{ textAlign: 'left', width: '100%' }}>
                                            Summary of the context:
                                        </h4>
                                        {
                                            <span style={{ float: 'left', textAlign: 'left', marginBottom: '10px', color: 'teal' }}>
                                                {selectedTrainingValue.summary_content}
                                            </span>
                                        }
                                    </div>
                                    <h4 style={{ textAlign: 'left', width: '100%' }}>Here are some questions that can be asked: </h4>
                                    <div>
                                        {
                                            showQuestions(selectedTrainingValue.recommendation_questions).map((question, index) => (

                                                <div key={index} style={{ float: 'left', marginBottom: '10px', color: 'teal', cursor: 'pointer' }} disabled={connecting} onClick={() => {
                                                    sendDefaultQuestion(question);
                                                    scrollChat();
                                                }}>
                                                    {question}
                                                </div>


                                            ))
                                        }
                                    </div>
                                </div>
                            ) : null
                        }
                    </div>
                    }

                    {chatLog.length > 0 &&
                        chatLog.map((chat, idx) => (
                            <AnswerChatComponent key={idx} idx={idx} chat={chat} suggestQuestion={suggestQuestion} handleSaveIntoSection={props.handleSaveIntoSection} handleClickOpenDetailReference={handleClickOpenDetailReference}></AnswerChatComponent>
                        ))}
                    {connecting && currentChat.chatPrompt && <AnswerChatComponent isConnecting={connecting} idx={'current-message'} chat={currentChat}></AnswerChatComponent>}

                </div>

                <div className="inputPromptWrapper">
                    <div className='inputPromptChat'>
                        <form onSubmit={handleSubmit}>
                            <input
                                name="inputPrompt"
                                disabled={connecting}
                                className="inputPrompttTextarea"
                                type="text"
                                value={inputPrompt}
                                onChange={(e) => {
                                    setInputPrompt(e.target.value);
                                    scrollChat();
                                }
                                }
                                autoFocus
                                placeholder='Type your message here'
                            ></input>
                            <Tooltip title="Enter">
                                <IconButton style={{ color: '#0297a1', borderRadius: '0px', border: '1px solid teal', width: '100px', cursor: 'pointer' }} disabled={connecting} onClick={handleSubmit}>
                                    <SendIcon style={{ cursor: 'pointer', fontSize: '1em' }} />
                                </IconButton>
                            </Tooltip>

                            {!props.trainingId && chatLog.length > 0 && (
                                <Tooltip title="Share">
                                    <IconButton disabled={connecting} onClick={() => { handleClickOpen('share') }}
                                        style={{ color: '#0297a1', borderRadius: '5px', fontSize: '1em', fontWeight: 'bold', cursor: 'pointer' }}
                                    >

                                        <ShareIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </form>
                    </div>
                </div>
            </section>
            <div>
                {open && documentType === 'share' && <ShareEmail sessionContentChat={chatLog} open={open} onSubmited={() => { setOpen(false); }} onClose={() => { setOpen(false); }}></ShareEmail>}
                {open && documentType === 'document' && <TrainingFileDocument open={open} onSubmited={() => { setOpen(false); }} selectedValue={handleSelectedValue} selectedValueTraining={handleSelectedValueTraining} onClose={() => { setOpen(false); }}></TrainingFileDocument>}
                {open && documentType === 'training' && <DocumentsOfTraining open={open} onSubmited={() => { setOpen(false); }} selectedTraining={selectedTrainingValue} onClose={() => { setOpen(false); }}></DocumentsOfTraining>}
            </div>
            <div>
                {openViewPdf && <ViewPdfDocument open={openViewPdf} filename={selectedDocument.filename} id={selectedDocument.id} onClose={() => { setOpenViewPdf(false); }}></ViewPdfDocument>}
            </div>
        </div>
    );
}
