import * as React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import axios from '../configs';
import AddChildSectionDialog from './AddChildSectionDialog';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { toast } from "react-toastify";
import DialogTitle from '@mui/material/DialogTitle';
import DetailSectionViewDialog from '../pages/DetailSectionViewDialog';
import { useAppStore } from "../utils/appstate";

export default function TreeViewSection(props) {
    const { onSubmited, sendNoteToSectionNodeChildren } = props;
    const [sections, setSections] = React.useState(props.sections);
    const [isLoading, setIsLoading] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const [choseSection, setChoseSection] = React.useState({})
    const [openView, setOpenView] = React.useState(false);

    const updateAppInfo = useAppStore((state) => state.updateAppInfo);
    const appInfo = useAppStore((state) => state.AppInfo);

    const maxNested = 15
    const auth_token = localStorage.getItem("auth_token");
    const auth_token_type = localStorage.getItem("auth_token_type");
    const token = auth_token_type + " " + auth_token;
    let maxNestedTree = 0;

    // handle len for filename  
    function truncateFilename(filename, maxLength) {
        if (!filename) {
            return '';
        }
        return filename.length > maxLength
            ? filename.slice(0, maxLength) + '...'
            : filename;
    }

    const MAX_FILENAME_LENGTH = 40;

    //------------------

    React.useEffect(() => {
        setSections([]);
        const rootSection = props.rootSection && props.rootSection.section_id ? props.rootSection : {}
        let currentMaxNested = 0;
        buildCurrentSection(props.sections, rootSection, currentMaxNested);

        let appInfoData = { ...appInfo }
        appInfoData.rootSection = rootSection;
        updateAppInfo(appInfoData);
    }, [props.rootSection, props.rootSection.section_id]);

    const handleClickSection = (section) => {
        //setSectionType(type);
        setOpen(true);
        setChoseSection(section);
        //getAllSections();       
    };

    const handleViewSection = (section) => {
        setOpenView(true);
        setChoseSection(section);
    }

    // ---------confirm dialog------
    const [selectedRow, setSelectedRow] = React.useState(null);
    const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);

    const handleDelete = (section) => {

        setConfirmDialogOpen(true);
        setSelectedRow(section)
    };

    const handleConfirmDelete = () => {
        handleDeleteSection(selectedRow);
        setConfirmDialogOpen(false);
    }
    //-------------------------

    // delete section data------
    const handleDeleteSection = async (section) => {
        // event.preventDefault();
        const data = {
            section_id: section.section_id,
            parent_id: section.parent_id,
            user_id: "",
            name: section.name,
            content: "",
            reference: "",
            type: section.type,
            training_id: "",
            document_id: "",
            created_user: "",
            created_at: "",
            modified_at: "",
            order_id: 0
        }
        await axios
            .post("/section/delete-section-data", data, { headers: { Authorization: token } })
            .then((response) => {

                if (!section.parent_id && props.reloadNotes) {
                    props.reloadNotes();
                    return;
                }
                // sort order node
                debugger
                console.log("Huong log appInfo: ", appInfo);
                sortOrderNodes(appInfo.rootSection.children);

                // let appInfoData = { ...appInfo }
                // appInfoData.rootSection = rootSection;
                // updateAppInfo(appInfoData);


                getAllSections();

                toast.success(response.data.detail);

            })
            .catch((error) => {
                // add error notif
                toast.error(error.response.data.detail);

            });
    };
    //-------end delete---    

    const getAllSections = async () => {

        try {
            const res = await axios.get("/section/get-list-sections-for-tree-view-section", {
                headers: { Authorization: token }
            })
                .then(response => {

                    setIsLoading(false);
                    const resTemp = response.data.result;
                    setSections(resTemp);
                    const rootSection = props.rootSection && props.rootSection.section_id ? props.rootSection : resTemp[0]
                    let currentMaxNested = 0;
                    buildCurrentSection(resTemp, rootSection, currentMaxNested);

                    // setCurrentRootSection(rootSection);
                    let appInfoData = { ...appInfo }
                    appInfoData.rootSection = rootSection;
                    updateAppInfo(appInfoData);
                });

        } catch (e) {
            console.error(e);
        }
    };

    // function addSecion(sectionData, parentSection){
    //     sectionData.order = parentSection.children.length + 1;
    // }

    // sort order for list notes
    const sortOrderNodes = async (section) => {
        debugger
        section.sort((a, b) => {
            if (a.order_id < b.order_id) { return -1; }
            if (a.order_id > b.order_id) { return 1; }
            return 0;
        });

        const reorderedSections = section.map((sec, index) => ({
            section_id: sec.section_id,
            order_id: index + 1,
        }));
        debugger
        await axios
            .post("/section/update-order-for-list-notes", reorderedSections, { headers: { Authorization: token } })
            .then((response) => {

                // toast.success(response.data.detail);

            })
            .catch((error) => {
                toast.error(error.response.data.detail);
            });
    }

    function buildCurrentSection(sections, rootSection, currentMaxNested) {
        //find child of root sections
        if (!rootSection.section_id) {
            return;
        }
        currentMaxNested += 1;
        if (currentMaxNested > maxNested) {
            return rootSection;
        }

        let childrenOfRoot = sections.filter(t => t.parent_id === rootSection.section_id);
        childrenOfRoot.forEach((child, index) => {
            child.order = index;
            buildCurrentSection(sections, child, currentMaxNested);
        });
        currentMaxNested += 1;
        childrenOfRoot.sort((a, b) => {
            if (a.order_id < b.order_id) { return -1; }
            if (a.order_id > b.order_id) { return 1; }
            return 0;
        });
        rootSection.children = childrenOfRoot;
    }

    const selectedSection = (item, parent) => {
        onSubmited(item);
        // this.props.sendNoteToSectionNodeChildren(item);
        sendNoteToSectionNodeChildren(item, parent);
    }


    function buildTreeView(section, parent) {
        const nameToDisplay = section.name ? truncateFilename(section.name, MAX_FILENAME_LENGTH) : '';
        maxNestedTree += 1;
        if (section.type === 'content') {
            // return <TreeItem onClick={() => { selectedSection(section) }} nodeId={section.section_id} label={<div style={{ display: 'flex', alignItems: 'center' }}>
            //     <span>{section.name}</span>
            //     <div style={{ display: 'flex', gap: '5px' }}>
            //         <DeleteOutlineOutlinedIcon onClick={() => handleDeleteSection(section)} style={{ color: 'red' }} />
            //         <VisibilityIcon onClick={() => handleViewSection(section)} style={{ color: 'rgb(2, 151, 161)' }} />
            //     </div>
            // </div>}>
            // </TreeItem>
            return <TreeItem className='tree-document-item-section' key={section.section_id} onClick={() => { selectedSection(section, parent); handleViewSection(section) }} nodeId={section.section_id}
                label={<span className='tree-document-text-section'>{section.name}
                    {/* <span onClick={() => { handleViewSection(section) }} style={{ paddingLeft: '10px', color: 'rgb(30, 144, 255)', float: 'right', fontSize: '1.5em', fontWeight: 'bold' }}>*</span> */}
                    <span onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleDelete(section) }} style={{ color: 'red', float: 'right', fontSize: '1.5em', fontWeight: 'bold' }}>-</span>
                </span>} >
            </TreeItem>
        }
        if (maxNestedTree > maxNested) {
            return <TreeItem className='tree-document-item-section' key={section.section_id} onClick={() => { selectedSection(section, parent) }} nodeId={section.section_id}
                label={<span className='tree-document-text-section'>{nameToDisplay}
                    <span title='add new note document' style={{ paddingLeft: '10px', color: 'rgb(30, 144, 255)', float: 'right', fontSize: '1.5em', fontWeight: 'bold' }} onClick={() => handleClickSection(section)}>+</span>
                    <span title='delete note' onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleDelete(section) }} style={{ color: 'red', float: 'right', fontSize: '1.5em', fontWeight: 'bold' }}>-</span>
                </span>} ></TreeItem>
        }

        if (section.children && section.children.length > 0) {
            return <TreeItem className='tree-document-item-section' key={section.section_id} onClick={() => { selectedSection(section, parent) }} nodeId={section.section_id}
                label={<span className='tree-document-text-section'>{nameToDisplay} <span title='add new note document' style={{ color: 'rgb(30, 144, 255)', float: 'right', fontSize: '1.5em', fontWeight: 'bold' }} onClick={() => {
                    handleClickSection(section)
                }}>+</span></span>}>
                {section.children.map(child => {
                    return buildTreeView(child, section)
                })}
            </TreeItem>
        }

        return <TreeItem className='tree-document-item-section' key={section.section_id} onClick={() => { selectedSection(section, parent) }} nodeId={section.section_id}
            label={<span className='tree-document-text-section'>{nameToDisplay}
                <span onClick={() => handleClickSection(section)} style={{ paddingLeft: '10px', color: 'rgb(30, 144, 255)', float: 'right', fontSize: '1.5em', fontWeight: 'bold' }}>+</span>
                <span onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleDelete(section) }} style={{ color: 'red', float: 'right', fontSize: '1.5em', fontWeight: 'bold' }}> - </span>
            </span>}>
        </TreeItem>
    }

    return (
        <>

            {props.rootSection && props.rootSection.section_id && <TreeView
                aria-label="file system navigator"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                sx={{ maxHeight: 750, flexGrow: 1, maxWidth: 800, overflowY: 'auto' }}
            >
                {
                    // buildTreeView(currentRootSection, maxNestedTree)
                    appInfo.rootSection ? buildTreeView(appInfo.rootSection, maxNestedTree) : ""
                }

            </TreeView>}

            {/* {sectionType = 'addChildSection' && <AddChildSectionDialog open={open} section={choseSection} onClose={() => { setOpen(false); }}></AddChildSectionDialog>} */}
            {open && <AddChildSectionDialog section={choseSection} open={open} onClose={() => { setOpen(false); }}></AddChildSectionDialog>}
            {openView && <DetailSectionViewDialog section={choseSection} open={openView} onClose={() => { setOpenView(false); }}></DetailSectionViewDialog>}

            {/*----ui dialog-for confirm delete------*/}
            <div>
                <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)} style={{ minWidth: '40%' }}>
                    <DialogTitle>Confirmation</DialogTitle>
                    <Divider />

                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete Note?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
                        <Button onClick={() => handleConfirmDelete()}>Delete</Button>
                    </DialogActions>
                </Dialog>
            </div>
            {/*-----end ui dialog-for confirm delete-----*/}
        </>

    );
}