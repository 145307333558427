import React, { useState } from "react";
import axios from './../configs'

const NavPrompt = ({ chatPrompt, sessionID, setChatLogHistory }) => {
  const chatPromptCharacters = chatPrompt.split("");

  // call api get session detail with sessionid
  const auth_token = localStorage.getItem("auth_token");
  const auth_token_type = localStorage.getItem("auth_token_type");
  const token = auth_token_type + " " + auth_token;
  let chatSessionDetailTemp = [];

  async function getSessionById(sessionId) {
    debugger
    axios.get("/chats/get-session-detail-by-session-id", {
      headers: { Authorization: token }, params: { session_id: sessionId },
    }).then((res) => {
      console.log("res", res);

      chatSessionDetailTemp = res.data.result.map((chat) => {
        let refer = [];
        try {
          refer = JSON.parse(chat.reference)
        } catch (e) {

        }
        return {

          chatPrompt: chat.question,
          botMessage: chat.answer,
          reference: refer
        }
      })

      setChatLogHistory(sessionId, chatSessionDetailTemp);
      console.log("chatSessionDetailTemp", chatSessionDetailTemp);
      setTimeout(() => {
        let objDiv = document.getElementById("chatlogWrapper");
        if (objDiv && objDiv.scrollHeight) objDiv.scrollTop = objDiv.scrollHeight;
      }, 150);

    }).catch(e => console.error(e));

  }

  return (
    <div className="navPrompt">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        fill="#0297a1"
        stroke="#0297a1"
        width={16}
        height={16}
      >
        <path
          fill="#0297a1"
          fillRule="evenodd"
          d="M15 3.25A2.25 2.25 0 0 0 12.75 1h-9.5A2.25 2.25 0 0 0 1 3.25v11a.75.75 0 0 0 1.26.55l2.801-2.6a.75.75 0 0 1 .51-.2h7.179A2.25 2.25 0 0 0 15 9.75v-6.5zm-2.25-.75a.75.75 0 0 1 .75.75v6.5a.75.75 0 0 1-.75.75H5.572a2.25 2.25 0 0 0-1.531.6L2.5 12.53V3.25a.75.75 0 0 1 .75-.75h9.5z"
          clipRule="evenodd"
        />
      </svg>
      <p onClick={async () => {
        getSessionById(sessionID);
        // setChatLog(chatSessionDetail);
      }}>
        {chatPromptCharacters.map((char, idx) => (
          <span key={idx} style={{ animationDelay: `${idx * 0.1}s` }}>
            {char}
          </span>
        ))}
      </p>
    </div>
  );
};

export default NavPrompt;
