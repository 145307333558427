import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogActions, DialogContentText, Divider, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import axios from '../configs';

export default function EditNodeNameDialog(props) {
    const { onClose, open, onSubmited } = props;
    const auth_token = localStorage.getItem("auth_token");
    const auth_token_type = localStorage.getItem("auth_token_type");
    const token = auth_token_type + " " + auth_token;


    const closeDialog = (e, reason) => {
        if (reason === 'escapeKeyDown') {
            return;
        }
        if (reason === 'backdropClick') {
            return;
        }
        onClose();
    };

    React.useEffect(() => {
        const dataFormEdit = {
            section_id: props.selectedNodeTree.section_id,
            parent_id: "",
            user_id: "",
            name: props.selectedNodeTree.name,
            content: "",
            reference: "",
            type: "",
            training_id: "",
            document_id: "",
            created_user: "",
            created_at: "",
            modified_at: "",
            order_id: 0
        }
        setFormEditNode(dataFormEdit);
    }, [props.selectedNodeTree]);

    const [formEditNode, setFormEditNode] = React.useState({})


    const onChangeFormEditNote = (label, event) => {
        setFormEditNode({ ...formEditNode, name: event.target.value });

    };


    const handleSaveChangeEditNote = async (event) => {
        debugger
        event.preventDefault();
        await axios
            .post("/section/edit-note-name", formEditNode, { headers: { Authorization: token } })
            .then((response) => {
                onSubmited(response);
                toast.success(response.data.detail);
            })
            .catch((error) => {
                toast.error(error.response.data.detail);

            });
    };


    return (
        <Dialog maxWidth={"sm"} open={open} onClose={closeDialog}>
            <DialogTitle>Edit Note Name</DialogTitle>
            <Divider />

            <DialogContent>
                <DialogContentText>
                </DialogContentText>
                <label htmlFor="name" className='font_text' style={{ paddingBottom: 10 }}>Name</label>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    value={formEditNode.name}
                    //label="Name"
                    type="text"
                    fullWidth
                    // variant="outlined"
                    variant="outlined"
                    onChange={(event) => {
                        onChangeFormEditNote("name", event)
                    }}
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>Cancel</Button>
                <Button onClick={handleSaveChangeEditNote}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}