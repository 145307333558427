import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogActions, Divider, SvgIcon, TextField } from '@mui/material';
import axios from './../configs'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import { toast } from 'react-toastify';
import { Link, useNavigate } from "react-router-dom";
import { useState } from 'react'

const DocumentOppredy = () => {
  //const { onClose } = props;
  const [description, setDescription] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [isPreview, setIsPreview] = React.useState(false);
  const navigate = useNavigate();

  const [checked, setChecked] = React.useState([]);
  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    {
      field: 'filename',
      headerName: 'File name',
      width: 350,
      editable: true,
    },

    {
      field: 'name',
      headerName: 'Created User',
      width: 200,
      editable: true,
    },


  ];
  const rows = [
    { id: 1, name: 'Snow', filename: 'OpReddy.SDP.1.0.5.1000' },
    { id: 2, name: 'Lannister', filename: 'OpReddy.SDP.1.0.4.1001' },
    { id: 3, name: 'Snow', filename: 'OpReddy.SDP.1.0.5.1000' },
    { id: 4, name: 'Lannister', filename: 'OpReddy.SDP.1.0.4.1001' },

  ];


  React.useEffect(() => {
  }, []);

  const [formRegister, setFormRegister] = useState({
    key: "",


  })

  const onChangeForm = (label, event) => {
    switch (label) {


      case "key":
        setFormRegister({ ...formRegister, key: event.target.value });
        break;

      default:
        break;
    }

  };

  //   call API get oppredy document
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    // Post to register API
    await axios
      .get(`/documents/register`, formRegister)
      .then((response) => {
        // move to sign in page           
        navigate("/login");
        // add successfully notif
        toast.success(response.data.detail);

      })
      .catch((error) => {
        // add error notif
        toast.error(error.response.data.detail);

      });
  };

  function ButtonCancle() {
    return navigate("/documentManagement");
  };
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  let formBuild = (<>
    <div className='DocumentManagement_container'>
      <div style={{ paddingTop: 15, paddingBottom: 15 }}>
        <h4 style={{ padding: 0, margin: 0, color: 'teal' }}>
          Document Opreddy
        </h4>


      </div>
      <form onSubmit={onSubmitHandler} style={{ marginTop: '15px' }}>
        <div className='form_login' style={{ paddingLeft: 20, paddingRight: 20 }}>

          <Grid container spacing={2}>
            <Grid item xs={10}>
              <div style={{ paddingBottom: 10 }}>

                <TextField id="key" style={{ width: '100%', backgroundColor: 'white' }}
                  required

                  label="API Key"
                  onChange={(event) => {
                    onChangeForm("key", event)
                  }}
                />

              </div>
            </Grid>
            <Grid item xs={2}>
              <div style={{ paddingBottom: 10 }}>
                <Button className='button_login' variant="contained" style={{ backgroundColor: 'teal', height: '55px' }}
                  type="submit">
                  <div role="status">
                    <span>Get</span>
                  </div>
                </Button>

              </div>
            </Grid>

          </Grid>



        </div>
      </form>
    </div>
    <div className='DocumentManagement_container'>
      <h4 style={{ color: 'teal' }}>List Oppreddy Document </h4>
      <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} >
        {rows.map((value) => {
          const labelId = `checkbox-list-secondary-label-${value}`;
          return (
            <ListItem
              key={value.id}
              secondaryAction={
                <Checkbox
                  edge="end"
                  onChange={handleToggle(value.id)}
                  checked={checked.indexOf(value.id) !== -1}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              }
              disablePadding
            >
              <ListItemButton>

                <ListItemText id={labelId} primary={`${value.filename}`} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>


    </div>
    <div style={{ marginRight: '8%' }}>
      <Button className='button_save_document_oppredy' variant="contained"  >

        <div role="status">
          <span>SAVE</span>
        </div>

      </Button>
      <Button onClick={ButtonCancle} className='button_cancle_document_oppredy' variant="contained" style={{ marginRight: '15px', backgroundColor: 'rgb(209, 202, 202)', color: 'teal' }} >

        <div role="status">
          <span>close</span>
        </div>

      </Button>
    </div>





  </>);

  if (isPreview) {
    formBuild = (<>
      <div>
        <div className="column" style={{ padding: '20px' }}>
          <TextField
            label={"Description"}
            placeholder="Description"
            type="text"
            variant="outlined"
            onChange={event => setDescription(event.target.value)}
            value={description || ''}
            required={true}
          />
        </div>
      </div>

    </>);
  }


  return (


    <div>
      {isLoading ? <div>Loading</div> : formBuild}
    </div>
  );






}
export default DocumentOppredy
