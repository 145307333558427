import React from 'react';
import './index.css';
import { createBrowserRouter, RouterProvider, Link, } from "react-router-dom";
import Register from './pages/Register';
import Forgot from './pages/Forgot';
import Login from './pages/Login';
import Reset from './pages/Reset';
import Profile from './pages/Profile'
import Setting from './pages/Setting'
import { ToastContainer } from 'react-toastify'
import "react-toastify/ReactToastify.min.css"
import { AuthProvider } from './utils/auth';
import { RequireAuth } from './utils/RequireAuth';
import Chat from './pages/Chat';
import './App.css';
import Divider from '@mui/material/Divider';
import UploadDocument from './pages/UploadDocument'
import DocumentManagement from './pages/DocumentManagement'
import TrainingData from './pages/TrainingData';
import TrainingDataDetail from './pages/TrainingDataDetail';
import DocumentUploader from './pages/Document'
import MainLayout from './components/MainLayout';
import LoginLayout from './components/LoginLayout';
import ExternalLogin from './components/ExternalLogin';
import User_Management from './pages/UserManagement';
import UserDetail from './pages/UserDetail';
import UpdateRoleUser from './pages/UpdateRoleUser';
import AddUser from './pages/AddUser';
import ChangePassword from './pages/ChangePassword';
import Migration from './pages/Migration'
import HomeChat from './pages/HomeChat';
import About from './pages/About'


function ErrorPage() {
  return (
    <div>
      <div>
        <h1>Oops! Nothing found</h1>
        <p>Login <Link to="/login"><span>Here</span></Link></p>
      </div>
    </div>
  )
}

const router = createBrowserRouter([
  {
    path: "/",
    element:
      <MainLayout>
        <HomeChat />
      </MainLayout>,
  },
  {
    path: "/login",
    element:
      <LoginLayout>
        <Login />
      </LoginLayout>,
  },
  {
    path: "/login-external",
    element:
      <LoginLayout>
        <ExternalLogin></ExternalLogin>
      </LoginLayout>,
  },
  {
    path: "/reset",
    element:
      <LoginLayout>
        <Reset></Reset>
      </LoginLayout>,
  },
  {
    path: "/register",
    element:
      <LoginLayout>
        <Register />
      </LoginLayout>,
  },
  {
    path: "/forgot",
    element:
      <LoginLayout>
        <Forgot />
      </LoginLayout>,
  },
  {
    path: "/home",
    element:
      <MainLayout>
        <HomeChat />
      </MainLayout>,
  },
  // {
  //   path: "/reset",
  //   element:
  //     <MainLayout>
  //       <Reset />
  //     </MainLayout>,
  // },
  {
    path: "/profile",
    element:
      <MainLayout>
        <Profile />
      </MainLayout>,
  },
  {
    path: "/setting",
    element:
      <MainLayout>
        <Setting />
      </MainLayout>,
  },
  {
    path: "/uploadDocument",
    element:
      <MainLayout>
        <UploadDocument />
      </MainLayout>,
  },
  {
    path: "/documentManagement",
    element:
      <MainLayout>
        <DocumentManagement />
      </MainLayout>,
  },
  {
    path: "Document",
    element:
      <MainLayout>
        <DocumentUploader />
      </MainLayout>,
  },
  {
    path: "*",
    element: <ErrorPage />
  },
  {
    path: "/training-data",
    element:
      <MainLayout>
        <TrainingData />
      </MainLayout>,
  },
  {
    path: "/training-data-detail",
    element:
      <MainLayout>
        <TrainingDataDetail />
      </MainLayout>,
  },
  {
    path: "/user-management",
    element:
      <MainLayout>
        <User_Management />
      </MainLayout>,
  },
  {
    path: "/user-detail",
    element:
      <MainLayout>
        <UserDetail />
      </MainLayout>,
  },
  {
    path: "/update-role-user",
    element:
      <MainLayout>
        <UpdateRoleUser />
      </MainLayout>,
  },
  {
    path: "/add-user",
    element:
      <MainLayout>
        <AddUser />
      </MainLayout>,
  },
  {
    path: "/change-password",
    element:
      <MainLayout>
        <ChangePassword />
      </MainLayout>,
  },
  {
    path: "/migrate",
    element:
      <MainLayout>
        <Migration />
      </MainLayout>,
  },
  
  {
    path: "/about",
    element:
      <MainLayout>
        <About />
      </MainLayout>,
  },
]

);

function App({ children }) {
  return (
    <>
      <ToastContainer />
      <RouterProvider router={router} />
    </>)

}


export default App;