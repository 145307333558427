import { useNavigate } from "react-router-dom";
import React from 'react'
import { toast } from 'react-toastify';
import axios from '../configs'
import './register.css';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogActions, Divider, IconButton } from '@mui/material'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import DeleteIcon from '@mui/icons-material/Delete';
import Spinner from "../components/Spinner";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const UploadDocument = (props) => {
    const navigate = useNavigate();
    const auth_token = localStorage.getItem("auth_token");
    const auth_token_type = localStorage.getItem("auth_token_type");
    const token = auth_token_type + " " + auth_token;
    const file = React.useRef()
    const { onClose, onSubmit } = props;
    const [files, setFiles] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isPreview, setIsPreview] = React.useState(false);
    // Register Form
    const closeDialog = (e, reason) => {
        if (reason === 'escapeKeyDown') {
            return;
        }
        if (reason === 'backdropClick') {
            return;
        }
        onClose();
    };

    React.useEffect(() => {
        setFiles([]);
    }, [])

    const handleFormSubmit = async () => {


        setIsLoading(true);
        const config = {
            headers: {
                'Content-Type': 'application/pdf',
                'Authorization': token
            },
        }

        var formData = new FormData();
        for (var i = 0; i < files.length; i++) {
            formData.append('files', files[i])
        }

        axios.post(`/documents/upload-files`, formData, config).then((response) => {
            toast.success(response.data.detail);
            console.log("upload file",response.data.detail);
            setIsLoading(false);
            setIsPreview(false)
            onSubmit(response);
            onClose();
            props.onUploadDoc(response); // to do auto check
        })
            .catch(err => {
                toast.error(err.response.data.detail);
                setIsLoading(false);
            });

    };

    const removeFile = (file) => {
        let newFiles = files.filter(t => t.name !== file.name);
        setFiles(newFiles);
        
    }

    const buttonCallBack = () =>{
       
        if(files.length >0 ){
            setFiles([]);
            
        }
        
        if(file.current){
            file.current.value =null;
        }
        setIsPreview(false);
        
    }   
    const uploadFile = (e) => {
       
        let filesUpload = [];
        for (let i = 0; i < e.target.files.length; i++) {
            let file = e.target.files[i];
            let needAddImage = files.filter(t => t.name === file.name).length === 0;
            if (needAddImage) {
                filesUpload.push(file);
            }
            else {
               
                file = files[0];
                console.log("file 1", file);
               
            }
        }
        setFiles(filesUpload);
        setIsPreview(true);
        
    }

        

    let formBuild = (<>

        {/* <div style={{ paddingTop: '25px', paddingBottom: '25px' }}>Add document.</div> */}
        <div style={{ textAlign: 'center', padding: '20px' }}>
            <Button onClick={() => { file.current.click(); }} 
            
                style={{ background: '#E4ECF7', border: 'none', height: '50px', maxWidth: '250px', width: '350px', margin: '15px auto' }}>

                <span style={{ marginLeft: '10px', fontSize: '14px', fontWeight: 'bold' }}>Choose document</span>
            </Button>
        </div>
        <div style={{ marginLeft: '30%', color: 'teal', marginTop: '-10px' }}>
            <div>Accepted file types: </div>
            <div>*.doc, *.docx, *.ppt, *.pptx, *.txt, *.pdf</div>
        </div>
    </>);


    if (isPreview) {

        formBuild = (<>
            <div className="column" style={{ margin: '20px auto', textAlign: 'center', width: '100%' }}>
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {files && files.length > 0 && files.map((fupload) =>
                    (<ListItem key={Math.random().toString(36).substring(2, 9)}
                        secondaryAction={
                            <IconButton edge="end" aria-label="delete" onClick={() => { removeFile(fupload) }}>
                                <DeleteIcon />
                            </IconButton>
                        }>
                        <ListItemAvatar>
                            <Avatar>
                                <PictureAsPdfIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={fupload.name} secondary={fupload.name} />

                    </ListItem>)
                    )}
                </List>
            </div>

        </>);
    }


    return (

        <Dialog
            disableEscapeKeyDown={true}
            fullWidth={true}
            maxWidth={"sm"}
            onClose={closeDialog} aria-labelledby="add-dialog-title" open={props.open}>

            <DialogTitle><div className="inlineTitle" style={{ fontSize: '20px' }}>Upload Document</div></DialogTitle>
            <Divider></Divider>
            <DialogContent>
                <div> <input ref={file} onChange={uploadFile} type='file' hidden multiple={true} accept=".doc, .docx, .ppt, .pptx, .txt, .pdf" /> </div>
                {isLoading ? <div><Spinner></Spinner></div> : formBuild}
            </DialogContent>
            <DialogActions>
                {isPreview === true &&
                    <div style={{ textAlign: 'center', padding: '20px' }}>
                        {/* <Button size="medium" className='wealth-button' style={{ maxWidth: '200px', minWidth: '100px' }} onClick={() => { setIsPreview(false) }}>
                            Back
                        </Button> */}
                        <Button size="medium" className='wealth-button' style={{ maxWidth: '200px', minWidth: '100px' }} onClick={ buttonCallBack }>
                            Back
                        </Button>
                        <Button disabled={files.length <= 0} variant="contained" size="medium" color="primary" className='wealth-button' onClick={handleFormSubmit} style={{ maxWidth: '200px', minWidth: '100px' }}>
                            Upload
                        </Button>
                    </div>
                }

                {!isPreview &&
                    <div style={{ textAlign: 'center', padding: '20px' }}>
                        <Button size="medium" className='wealth-button' style={{ maxWidth: '200px', minWidth: '100px' }} onClick={closeDialog}>
                            Close
                        </Button>
                    </div>
                }
            </DialogActions>
        </Dialog>
    );



}

export default UploadDocument
