import { useNavigate, } from "react-router-dom"
import { useState } from "react";
import { toast } from "react-toastify";
import axios from '../configs';
import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Utils from './../utils/utils';
import { DataGrid } from '@mui/x-data-grid';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import '../App.css'
import './Training.css'
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { ElectricBolt, DoneAll } from '@mui/icons-material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import TrainingEdit from "./TrainingEdit";
import TrainingAddDocument from "./TrainingAddDocument";
import TrainingAddQA from "./TrainingAddQA";
import Spinner from "../components/Spinner";
import Chat from "./Chat";
import { Alert, AlertTitle, IconButton, Tooltip } from "@mui/material";
import ViewPdfDocument from '../components/ViewDocument';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PreviewIcon from '@mui/icons-material/Preview';

const TrainingDataDetail = () => {

  const steps = ['Training Document Information', 'Test and Validate', 'Complete '];
  const [isLoading, setIsLoading] = useState(false);
  const auth_token = localStorage.getItem("auth_token");
  const auth_token_type = localStorage.getItem("auth_token_type");
  const token = auth_token_type + " " + auth_token;
  const query = new URLSearchParams(window.location.search);
  const training_id = query.get('id');
  const [trainingStatus, setTrainingStatus] = React.useState('');
  const history = useNavigate();
  const [trainingData, setTrainingData] = useState({});
  const [open, setOpen] = React.useState(false);
  const [openViewPdf, setOpenViewPdf] = React.useState(false);
  const [selectedView, setSelectedView] = React.useState();

  const [trainingDataDetail, setTrainingDataDetail] = React.useState([]);
  const [qatrainingDataDetail, setQATrainingDataDetail] = React.useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [openEditTraining, setOpenEditTraining] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [documentType, setDocumentType] = useState('');

  const DOCUMENT_TYPE = "document";
  const QA_TYPE = "Q&A";

  const handleClickOpen = (type) => {
    setDocumentType(type);
    setOpen(true);
  };

  const handleDelete = () => {
    deleteTrainingDetail(selectedRow);
    setConfirmDialogOpen(false);
  };


  React.useEffect(() => {
    fetchDataTraining(training_id);
    fetchDataTrainingDetail(training_id);
  }, [])

  const rows = trainingDataDetail;

  const [currentCount, setCount] = useState(50);

  React.useEffect(() => {

    if (trainingStatus === 'preparing') {
      return;
    }


    if (trainingStatus === 'error') {
      setActiveStep(0);
      return;
    }

    const interval = setInterval(() => {
      fetchDataTraining(training_id);
      setCount((currentCount) => --currentCount);
    }, 2000);

    if (trainingStatus === 'completed' || trainingStatus === 'approved') {
      clearInterval(interval);
    }

    // redirect once count is equal to 0
    if (currentCount === 0) {
      setTrainingStatus('error');
      clearInterval(interval);
    }

    // cleanup
    return () => clearInterval(interval);
  }, [currentCount, trainingStatus]);


  const fetchDataTraining = async (trainingId) => {
    try {
      const res = await axios.get("/training/get-training-info-by-id", {
        headers: { Authorization: token }, params: { training_id: trainingId }
      });

      setTrainingData(res.data.result);

      setTrainingStatus(res.data.result.status);
    } catch (e) {
      console.error(e);
    }
  }

  const columns = [
    {
      field: 'file_name_doc',
      headerName: 'Document',
      width: 350,
      editable: true,
    },
    {
      field: 'type_doc',
      headerName: 'Type document',
      // type: 'number',
      width: 200,
      editable: true,
    },
    {
      field: 'created_user',
      headerName: 'Created User',
      // type: 'number',
      width: 200,
      editable: true,
    },

    {
      field: 'created_at',
      headerName: 'Created Date',
      // type: 'number',
      width: 200,
      editable: true,
      valueFormatter: (params) => {
        if (params.value === 'N/A') {
          return params.value;
        }
        return Utils.formatDateTime(params.value);
      },
    },
    {
      field: 'Actions',
      headerName: 'Actions', width: 250,
      renderCell: (params) => (
        <>
          <Tooltip title="View document">
            <IconButton
              aria-label="view document" size="large"
              onClick={(event) => {
                setSelectedView(params.row);
                setOpenViewPdf(true);
              }}
              style={{ padding: 10 }}
            >
              <PreviewIcon style={{ color: 'teal' }} />

            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              aria-label="Delete" size="large"
              onClick={(event) => {
                setSelectedRow(params.row.id);
                setConfirmDialogOpen(true);
              }}
              style={{ padding: 10 }}
            >
              <DeleteOutlineOutlinedIcon style={{ color: 'red' }} />

            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  const fetchDataTrainingDetail = async (trainingId) => {
    try {
      const res = await axios.get("/training/get-list-training-detail-by-id", {
        headers: { Authorization: token }, params: { training_id: trainingId }
      });

      const trainingDetailTemp = res.data.result.map((trainingdetail, idx) => ({
        id: trainingdetail.training_detail_id,
        training_id: trainingdetail.training_id,
        document_id: trainingdetail.document_id,
        created_user: trainingdetail.created_user_name,
        modified_user: trainingdetail.created_user_name,
        created_at: trainingdetail.created_at,
        modified_at: trainingdetail.modified_at,
        file_name_doc: trainingdetail.file_name_doc,
        type_doc: trainingdetail.type_doc,
        type: trainingdetail.type
      }));

      const trainingDetailList = trainingDetailTemp.filter(x => x.type === DOCUMENT_TYPE);
      const QaTrainingDetailList = trainingDetailTemp.filter(x => x.type === QA_TYPE);
      setTrainingDataDetail(trainingDetailList);
      setQATrainingDataDetail(QaTrainingDetailList);
    } catch (e) {
      console.error(e);
    }
  }


  const deleteTrainingDetail = async (trainingDetailId) => {
    // event.preventDefault();
    const data = {
      training_detail_id: trainingDetailId,
      training_id: "",
      document_id: "",
      created_user: "",
      modified_user: "",
      type: "",
      
    }
    try {
      await axios
        .post("/training/delete-training-detail-by_detail-id", data, { headers: { Authorization: token } })
        .then((response) => {
          fetchDataTrainingDetail(training_id);
          toast.success(response.data.detail);
        })
        .catch((error) => {
          // add error notif
          toast.error(error.response.data.detail);
        });

    } catch (e) {
      console.error(e);
    }
  }

  const handleGenerateVector = async () => {
    setIsLoading(true);
    const data = {
      training_id: trainingData.training_id,
      name: trainingData.name,
      version: trainingData.version,
      status: trainingData.status,
      vector_file_path: '',
      json_file_path: '',
      created_user: trainingData.created_user,
      modified_user: trainingData.modified_user,
      recommendation_questions: '',
      summary_content:''
    }

    axios.post('/vector/generate', data, {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        handleNext();
        setTrainingStatus('new');
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        console.error(error);
      });
  }

  const handleApproval = () => {

    setIsLoading(true);
    const data = {
      training_id: trainingData.training_id,
      name: trainingData.name,
      version: trainingData.version,
      status: trainingData.status,
      vector_file_path: trainingData.vector_file_path,
      json_file_path: trainingData.json_file_path,
      created_user: trainingData.created_user,
      modified_user: trainingData.modified_user,
      recommendation_questions: trainingData.recommendation_questions,
      summary_content: trainingData.summary_content
    }
    axios.post('/vector/approve', data, {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        handleNext();
        setTrainingStatus('new');
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        console.error(error);
      });
  };



  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const generateForm = (step) => {
    switch (step) {
      case 0:
        return <React.Fragment>
          <div style={{ padding: '20px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ fontSize: '1.5em', fontWeight: 'bold' }}>Overview</div>
              <div>
                <Button onClick={() => setOpenEditTraining(true)} size="small"
                  style={{ padding: 10 }} variant="outlined">
                  <EditIcon />
                </Button>
              </div>
            </div>
            <div>
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Grid container spacing={4}>
                    <Grid item xs={3}>
                      <div><span style={{ fontWeight: 'bold' }}>Name: </span>{trainingData && trainingData.name}</div>
                    </Grid>
                    <Grid item xs={2}>
                      <div><span style={{ fontWeight: 'bold' }}>Version: </span>{trainingData && trainingData.version}</div>
                    </Grid>
                    <Grid item xs={2}>
                      <div><span style={{ fontWeight: 'bold' }}>Status: </span>{trainingData && trainingData.status}</div>
                    </Grid>
                    <Grid item xs={2}>
                      <div><span style={{ fontWeight: 'bold' }}>Created by: </span>{trainingData && trainingData.created_user_name}</div>
                    </Grid>
                    <Grid item xs={3}>
                      <div><span style={{ fontWeight: 'bold' }}>Created date: </span>
                        {trainingData && trainingData.created_at ? Utils.formatDateTime(trainingData.created_at) : 'N/A'}
                      </div>
                    </Grid>
                  </Grid>

                </CardContent>
              </Card>


            </div>

            {/* 1. training detail */}
            <div style={{ marginTop: '10px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 0 10px 0' }}>
                <div style={{ fontSize: '1.5em', fontWeight: 'bold' }}>1. Documents</div>
                <div>
                  <Button onClick={() => { handleClickOpen('document') }} variant="outlined" startIcon={<AddIcon />}>
                    Add New
                  </Button>

                  <Button disabled={(rows && rows.length <= 0) && (qatrainingDataDetail && qatrainingDataDetail.length <= 0)} onClick={handleGenerateVector} variant="contained" startIcon={<ElectricBolt />}
                    style={{ marginLeft: 10 }} >
                    Start Training
                  </Button>
                </div>
              </div>
              <div>
                <React.Fragment>
                  <CssBaseline />
                  <Box sx={{ height: 300, width: '100%' }}>
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 5,
                          },
                        },
                      }}
                      pageSizeOptions={[5]}
                      checkboxSelection
                      disableRowSelectionOnClick
                    />
                  </Box>

                </React.Fragment>
              </div>
            </div>

            <div style={{ marginTop: '10px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 0 10px 0' }}>
                <div style={{ fontSize: '1.5em', fontWeight: 'bold' }}>2. Q&A (Optional)</div>
                <div>
                  <Button onClick={() => { handleClickOpen('Q&A') }} variant="outlined" startIcon={<AddIcon />}>
                    Add New Q&A
                  </Button>
                </div>
              </div>
              <div>
                <React.Fragment>
                  <CssBaseline />
                  <Box sx={{ height: 300, width: '100%' }}>
                    <DataGrid
                      rows={qatrainingDataDetail}
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 5,
                          },
                        },
                      }}
                      pageSizeOptions={[5]}
                      checkboxSelection
                      disableRowSelectionOnClick
                    />
                  </Box>

                </React.Fragment>
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 0 10px 10px' }}>
              {trainingStatus === 'completed' && <Button disabled={rows && rows.length <= 0}
                onClick={handleNext}
                variant="outlined"
                style={{ marginRight: '15px', padding: 10 }}
              >
                Next
              </Button>}
              {/* <Button disabled={rows && rows.length <= 0} onClick={handleGenerateVector} variant="contained" startIcon={<ElectricBolt />}
                style={{ padding: 10 }} >
                Start Training
              </Button> */}
            </div>
          </div>
        </React.Fragment>

      case 1:
        return <React.Fragment>

          <div style={{ fontSize: '1.5em', fontWeight: 'bold' }}></div>
          {trainingStatus !== 'completed' ? <Spinner></Spinner> :
            <div className="test-app">
              <Chat trainingId={trainingData.training_id} ></Chat>

            </div>}
          <div style={{ float: 'right' }}>
            <Button disabled={rows && rows.length <= 0} onClick={() => { handleBack() }} variant="outlined"
              style={{ padding: 10 }} >
              Back
            </Button>
            {/* <Button disabled={rows && rows.length <= 0} onClick={() => { handleNext() }} variant="outlined"
              style={{ padding: 10 }} >
              Next
            </Button> */}

            <Button onClick={handleApproval} variant="contained" startIcon={<DoneAll />}
              style={{ padding: 10, marginLeft: 10 }} >
              Approve
            </Button>
          </div>
        </React.Fragment>;

      case 2:
        return <React.Fragment>
          <div style={{ padding: '40px' }}>

            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              This is a success training — <strong><a href="/">check it out!</a></strong>
            </Alert>
          </div>
        </React.Fragment>;

      default:
        break;
    }
  }

  return (
    <div style={{ padding: '20px' }}>
      <div style={{ height: '50px' }}>
        <Breadcrumbs aria-label="breadcrumb">
          <a
            underline="hover"
            color="inherit"
            onClick={() => { history('/training-data'); }}
          >
            Training
          </a>
          <Typography color="text.primary">{trainingData && trainingData.name}</Typography>
        </Breadcrumbs>
      </div>
      <div sx={{ width: '100%' }}>
        {openEditTraining && <TrainingEdit open={openEditTraining} trainingData={trainingData} onSubmited={() => { fetchDataTraining(training_id); setOpenEditTraining(false); }} onClose={() => { setOpenEditTraining(false); }}></TrainingEdit>}
        {open && documentType == 'document' && <TrainingAddDocument open={open} trainingdocs={trainingDataDetail} id={training_id} type={documentType} onSubmited={() => { fetchDataTrainingDetail(training_id); setOpen(false); }} onClose={() => { setOpen(false); }}></TrainingAddDocument>}
        {open && documentType == 'Q&A' && <TrainingAddQA open={open} trainingdocs={trainingDataDetail} id={training_id} type={documentType} onSubmited={() => { fetchDataTrainingDetail(training_id); setOpen(false); }} onClose={() => { setOpen(false); }}></TrainingAddQA>}
        {openViewPdf && <ViewPdfDocument open={openViewPdf} filename={selectedView.file_name_doc} id={selectedView.document_id} onClose={() => { setOpenViewPdf(false); }}></ViewPdfDocument>}
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <div>
          {generateForm(activeStep)}
        </div>
      </div>
      <Container style={{ maxWidth: '100%', marginTop: '20px' }}>


        <div>
          <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)} style={{ minWidth: '40%' }}>
            <DialogTitle>Confirmation</DialogTitle>
            <Divider />

            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete Training Data?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
              <Button onClick={handleDelete}>Delete</Button>
            </DialogActions>
          </Dialog>
        </div>

      </Container>
    </div>
  )
}

export default TrainingDataDetail