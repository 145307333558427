import React from 'react'
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import axios from './../configs'
import './register.css';
import { useAppStore } from "../utils/appstate";
import Divider from '@mui/material/Divider';
import Chat from './Chat';
import ChatUtils from '../utils/chat_utils';
import NewChat from '../components/NewChat';
import NavPrompt from '../components/NavPrompt';
import NavLinks from '../components/NavLink';
import SectionNote from '../components/SectionNote';

export default function HomeChat() {
    const navigate = useNavigate();
    const [sessionChat, setSessionChat] = useState([]);
    const [showMenu, setShowMenu] = useState(false);
    const [selectedTrainingValue, setSelectedTrainingValue] = useState({});
    const [isLoading, setIsLoading] = React.useState(true);
    const [chatLog, setChatLog] = useState([]);
    const [sessionId, setSessionId] = useState('');

    const updateAppInfo = useAppStore((state) => state.updateAppInfo);
    const appInfo = useAppStore((state) => state.AppInfo);
    const token = ChatUtils.getToken();

    const [section, setSection] = useState({});

    React.useEffect(() => {
        let trainingId = ChatUtils.getStoreTraining();
        let sessionIdStorage = ChatUtils.getStoreSession();
        setSessionId(sessionIdStorage);
        if (trainingId) {
            getHistoryChat(trainingId);
        }
        ChatUtils.storeDocumentId("");

        loadDefaultTraining(trainingId);
    }, []);

    const onSelectedNode = (item) => {
        setSection(item);

    }

    // handle len for filename  


    const handleSaveIntoSection = async (chat) => {
        debugger
        if (!section || !section.section_id || section.type == "content") {
            alert("Please select a valid section to add content.");
            return;
        }

        const subName = chat.botMessage ? ChatUtils.truncateFilename(chat.botMessage, ChatUtils.MAX_FILENAME_LENGTH) : '';
        let documentId = ChatUtils.getStoreDocumentId();
        let trainingId = ChatUtils.getStoreTraining();

        const data = {
            section_id: "",
            parent_id: section.section_id,
            user_id: "",
            name: subName,
            content: chat.botMessage,
            // reference: chat.reference[0].metadata.source ? chat.reference[0].metadata.source : '',
            reference: "",
            type: "content",
            training_id: trainingId,
            document_id: documentId,
            created_user: "",
            created_at: "",
            modified_at: "",
            order_id: 0
        };
        let orderId = 0;
        debugger
        if (appInfo.rootSection.children.length > 0) {
            const lastItem = appInfo.rootSection.children[appInfo.rootSection.children.length - 1];
            console.log("lastItem: ", lastItem)
            if (!lastItem.order) {
                lastItem.order = 0;
            }
            orderId = lastItem.order + 1;
            data.order_id = orderId;
        }
        await axios
            .post("/section/add-content-reference-for-section-with-parentId", data, { headers: { Authorization: token } })
            .then((response) => {
                debugger
                let appInfoData = { ...appInfo }
                addNewNodeToTree(appInfoData.rootSection, section, response.data.result);
                updateAppInfo(appInfoData);

                toast.success(response.data.detail);

            })
            .catch((error) => {
                // add error notif
                toast.error(error.response.data.detail);

            });
    }

    function loadDefaultTraining(trainingId) {

        setIsLoading(true)
        axios.get("/training/get-list-approved-training-for-chat-with-search-keyword", {
            headers: { Authorization: token },
            params: { key_search: "" }
        }).then((res) => {

            if (res.data.result.length === 0) {
                return;
            }

            let result_first = {}
            let lstTraining = res.data.result;
            if (trainingId) {
                result_first = lstTraining.find(item => item.training_id === trainingId);
            } else {
                result_first = lstTraining.find(item => item.is_used === 1);
            }

            let training_id = result_first ? result_first.training_id : "";
            ChatUtils.storeTraining(training_id);
            setSelectedTrainingValue(result_first);
            setIsLoading(false);

        }).catch(e => {
            setIsLoading(false)
        });
    }


    function setChatLogHistory(sessionId, data) {
        ChatUtils.storeSession(sessionId);
        setSessionId(sessionId);
        setChatLog([...data]);
    }

    function newChat() {
        let sessId = ChatUtils.uuidv4();
        ChatUtils.storeSession(sessId);
        setSessionId(sessId);
        ChatUtils.Logs = [];
        setChatLog([]);
        let trainingId = ChatUtils.getStoreTraining();
        loadDefaultTraining(trainingId);
    }

    const getHistoryChat = (trainingId) => {

        axios.get("/chats/get-session-chat-for-nav-prompt", {
            headers: { Authorization: token },
            params: { training_id: trainingId }
        }).then((res) => {
            setSessionChat([res.data.result]);
        }).catch(e => console.error(e));
    }


    const deleteAllSession = async () => {
        let trainingId = ChatUtils.getStoreTraining();
        const data = {
            training_id: trainingId
        }
        await axios
            .post("/chats/delete-all-session-chat", data, { headers: { Authorization: token } })
            .then((response) => {
                setSessionChat([]);
                toast.success(response.data.detail);
            })
            .catch((error) => {
                toast.error(error.response.data.detail);
            });
    };



    const addNewNodeToTree = (tree, targetNode, newNode) => {

        if (tree.section_id === targetNode.section_id) {
            tree.children.push(newNode);
            return true;
        }

        let result = false;
        tree.children.forEach((child) => {
            if (result) {
                return true;
            }
            let found = addNewNodeToTree(child, targetNode, newNode);
            if (found) {
                result = true;
            }
        });
        return result;
    }


    return <div style={{ display: 'flex', flexFlow: 'row' }}>

        {appInfo.SideMenu && <aside className="sideMenu">
            <NewChat setChatLog={setChatLog} setShowMenu={setShowMenu} newChat={newChat} />
            <div className='menuContent'>
                {!sessionChat[0] &&
                    <div className="navPromptWrapper">
                    </div>
                }

                {sessionChat[0] &&
                    <div style={{ maxHeight: '650px', overflowY: 'auto' }}>
                        {sessionChat && sessionChat[0]?.map(
                            (chat, idx) =>
                                <NavPrompt chatPrompt={chat.title} key={idx} sessionID={chat.session_id} setChatLogHistory={setChatLogHistory} />
                        )}
                    </div>
                }

                <div className='sideBottom'>

                    <Divider />

                    {sessionChat[0] && sessionChat[0].length > 0 && (
                        <div onClick={deleteAllSession}>

                            <NavLinks
                                className='nav-chat'
                                svg={
                                    <svg
                                        fill="#0297a1"
                                        viewBox="0 0 24 24"
                                        data-name="Flat Line"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="icon flat-line"
                                        stroke="#0297a1"
                                        width={23}
                                        height={23}
                                    >
                                        <path
                                            d="M5 8h13a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H5V8Z"
                                            transform="rotate(90 12 14)"
                                            style={{
                                                fill: "#fff202022",
                                                strokeWidth: 2,
                                            }}
                                        />
                                        <path
                                            d="M16 7V4a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v3"
                                            style={{
                                                fill: "none",
                                                stroke: "#fff202022000000",
                                                strokeLinecap: "round",
                                                strokeLinejoin: "round",
                                                strokeWidth: 2,
                                            }}
                                        />
                                        <path
                                            data-name="primary"
                                            d="M10 11v6m4-6v6M4 7h16m-2 13V7H6v13a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1Z"
                                            style={{
                                                fill: "none",
                                                stroke: "#fff202022000000",
                                                strokeLinecap: "round",
                                                strokeLinejoin: "round",
                                                strokeWidth: 2,
                                            }}
                                        />
                                    </svg>
                                }
                                text="Clear Conversations"
                                setChatLog={setChatLog}
                            />
                        </div>
                    )}
                </div>
            </div>
        </aside>}
        {!isLoading && <Chat sessionId={sessionId} getHistoryChat={getHistoryChat} chatLog={chatLog} selectedTraining={selectedTrainingValue} handleSaveIntoSection={handleSaveIntoSection} />}
        <div>
            {/* ----outline for export file document------- */}
            {(appInfo.SideMenuSection || appInfo.SideMenuSection === undefined) && (<aside className="sideMenuRight">
                <div className="menuContent" style={{ maxHeight: '650px', overflowY: 'auto' }}>
                    <SectionNote onSelectedNode={onSelectedNode} />
                </div>
            </aside>)}
            {/* -------end outline------------ */}
        </div>
    </div>;
}