import { useNavigate,Link } from "react-router-dom"
import { useState } from "react";
import { toast } from "react-toastify";
import axios from '../configs';
import * as React from 'react';
import './register.css';
import '../App.css'
import PersonIcon from '@mui/icons-material/Person';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Check } from "@mui/icons-material";

const UpdateRoleUser = () => {
  const history = useNavigate();
  const navigate = useNavigate();
  const auth_token = localStorage.getItem("auth_token");
  const auth_token_type = localStorage.getItem("auth_token_type");
  const token = auth_token_type + " " + auth_token;
  const query = new URLSearchParams(window.location.search);
  const user_id = query.get('userid');
 
  const [currentRole, setCurrentRole] =  React.useState({
    currentRoleId:"",
    //currentRoleName: ""
   })
  //const [checked, setChecked] = React.useState([]);
  const [roles, setRoles] = React.useState([]);
  console.log("userid", user_id);
  const [formRegister, setFormRegister] = useState({
    name: "",
    username: "",
    email: "",
    role_name: "",
    id:"",
    roleid:""
    
})
  

let listRole= [];
const getallRole = async () => {
  axios.get(`/users/get_all_role_user`, {
       headers: { Authorization: token },
     }).then((response) => {
       //console.log("role",response.data.result);
       response.data.result.map((item) => {
          listRole.push ({
             id: item.id,
             role_name: item.role_name
          })
            }
      );
      
      
      setRoles(listRole);
      toast.success(response.data.result);  
     })   
   .catch ((error) => {
     console.error(error);
   })
 };

 
 console.log("roles", roles);
  const onSubmitGetInfoUser = async () => {
   axios.get(`/users/get_info_user_byid`, {
        headers: { Authorization: token }, params: {userid:user_id  }
      }).then((response) => {
        //console.log("res",response);
        setFormRegister({...response.data.result});
        
        const item = {
          currentRoleId: response.data.result.roleid,
          //currentRoleName: response.data.result.role_name
        }
        setCurrentRole(item);
        
      })   
    .catch ((error) => {
      console.error(error);
    })
  };
   
  console.log("formRegister", formRegister);  
  
  console.log("currentRole",currentRole);
  

  React.useEffect(() => {
    onSubmitGetInfoUser(); 
    getallRole();
  }, []);


  const onSubmitUpdateRole = async (event) => {
    event.preventDefault();
    
    await axios
        .post(`/users/update-role-user`, formRegister,{
            headers: { Authorization: token },
          })
        .then((response) => {
         
           //navigate("/user-detail");
           history('/user-detail?userid=' + encodeURIComponent(formRegister.id));
            console.log(response)
            toast.success(response.data.detail);

        })
        .catch((error) => {
            // add error notif
            toast.error(error.response.data.detail);

        });

      }
        
      function ButtonCancle() {
        return  history('/user-detail?userid=' + encodeURIComponent(formRegister.id));
        
    };
    
    
    const handleChange = (event) => {
      console.log("event.target.value",event.target.value);
      const item = {
        currentRoleId: event.target.value
        
      }
      setCurrentRole(item);
      const role = roles.find(x=> x.id===event.target.value);
      formRegister.roleid=event.target.value;
      formRegister.role_name = role.role_name;
      
    };

return (
    <>
        <div className='profile_container'>
            <div style={{paddingTop:15, paddingBottom:15}}>
                <p className='login_header' style={{padding:0, margin:0}}>
                    List Role User
                </p>
               
                <Divider style={{marginLeft:20, marginRight:20,marginTop: 20}} />
            </div>
        </div>   
        <div className='profile_container'>
         
              <FormControl>
                
                  <FormLabel id="demo-controlled-radio-buttons-group">Role Name</FormLabel>
                 
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={currentRole.currentRoleId}
                        onChange={handleChange}
                       
                      >
                         {roles.map((data) => {
                            return (
                             
                              <FormControlLabel key={data.id} value={data.id} control={<Radio />} label={data.role_name} />
                            );
                          
                       })}  
                      </RadioGroup> 


     
                   
              </FormControl> 
        </div>

        <div style={{ paddingBottom: 20 }} className='profile_container'>
                        <Button  onClick={ButtonCancle}  className='button_cancle' variant="contained" style={{ backgroundColor: 'red', margin: '20px 0px' }}
                        >
                            <div role="status">
                                <span>Cancel</span>
                            </div>
                        </Button>
                        <Button onClick={onSubmitUpdateRole} className='button_setting' variant="contained" style={{ backgroundColor: 'teal', margin: '20px' }}
                            type="submit">
                            <div role="status">
                                <span>Save</span>
                            </div>
                        </Button>



        </div>

    </>  
  )

}
export default UpdateRoleUser